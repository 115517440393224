export const copyToClipboard = (element, value) => {
  // element needs to be a hidden input
  // value will be assigned to the input, then copied
  element.value = value;
  element.select();

  document.execCommand('copy');
};

export const getReportCreatorCompany = (company) => {
  /**
   * -----------------------------------
   * Get object, which holds name and information (address, email, phone) of the company, who have created a report.
   * -----------------------------------
   */

  let companyInfo = '';

  // add person name, who have made a report
  if (company.user !== '' && company.user !== null) {
    companyInfo = companyInfo + 'Udført af ' + company.user;
  }

  // add address like "Bernhard Bangs Alle 77, 2000 Frederiksberg"
  // addressAdded means if Street name and house number is added.
  // If Street and number does not present, we do not add postnumber and city.
  let addressAdded = false;
  if (company.address1 !== '' && company.address1 !== null) {
    companyInfo = companyInfo + '\n' + company.address1 + ',';
    addressAdded = true;
  }
  if (
    company.address2 !== '' &&
    company.address2 !== null &&
    addressAdded === false
  ) {
    companyInfo = companyInfo + '\n' + company.address2 + ',';
    addressAdded = true;
  }
  if (addressAdded) {
    if (company.postcode !== '' && company.postcode !== null) {
      companyInfo = companyInfo + ' ' + company.postcode;
    }
    if (company.city !== '' && company.city !== null) {
      companyInfo = companyInfo + ' ' + company.city;
    }
  }

  // add email
  if (company.email !== '' && company.email !== null) {
    companyInfo = companyInfo + '\nMail: ' + company.email;
  }

  // add phone number
  if (company.phone !== '' && company.phone !== null) {
    companyInfo = companyInfo + '\nTlf. ' + company.phone;
  }

  let reportCreator = {
    companyName: company ? company.name : '?',
    companyInfo: companyInfo,
  };

  return reportCreator;
};

export const getLocationsFromJSON = (property, reportType) => {
  if (!property.reports[reportType]) return { buildings: [] };

  return property.reports[reportType].buildings
    .reduce((acc, building) => {
      const issues = building.issues.map((issue) => ({
        locationCategory: issue.location.floor,
        locationName: issue.location.room,
      }));

      return [...acc, ...issues];
    }, [])
    .filter((location, index, self) => {
      // returns unique locations
      const me = location.locationCategory + location.locationName;
      const rest = self.map((x) => x.locationCategory + x.locationName);

      return rest.indexOf(me) === index;
    })
    .map((location, index) => ({
      ...location,
      locationId: Math.abs(index),
    }));
};

export const getDefectsFromJSON = (property, reportType) => {
  if (!property.reports[reportType]) return { buildings: [] };

  const reportData = property.reports[reportType];

  const locations = getLocationsFromJSON(property, reportType);

  // collect all defects from each building, adding the correct location id
  return reportData.buildings.reduce((acc, building, index) => {
    const issues = building.issues.map((issue) => {
      return {
        building: {
          buildingName: building.name,
          buildingIndex: index,
        },
        location: locations.find(
          (l) =>
            l.locationCategory === issue.location.floor &&
            l.locationName === issue.location.room,
        ),
        evaluation: getDefectEvaluation(issue.evaluation, reportType),
        damage: issue.damage,
        risk: issue.risk || '',
        note: issue.comments || '',
      };
    });

    return [...acc, ...issues];
  }, []);
};

export const getDefectEvaluation = (evaluation, reportType) => {
  // returns an object containing defect label, level and sorting order
  // used in getDefectsFromJSON
  switch (evaluation) {
    case 'rød':
      return {
        defectLabel:
          reportType === 'electrical' ? 'Risiko for brand' : 'Kritiske skader',
        defectLevel: reportType === 'electrical' ? 'risk-of-fire' : 'critical',
        sortingOrder: 1,
      };
    case 'gul':
      return {
        defectLabel:
          reportType === 'electrical' ? 'Risiko for stød' : 'Alvorlige skader',
        defectLevel: reportType === 'electrical' ? 'risk-of-chock' : 'serious',
        sortingOrder: 2,
      };
    case 'grå':
      return {
        defectLabel:
          reportType === 'electrical'
            ? 'Ulovlige elinstallationer'
            : 'Mindre alvorlige skader',
        defectLevel:
          reportType === 'electrical'
            ? 'illegal-installations'
            : 'less-serious',
        sortingOrder: 3,
      };

    case 'u/n':
    case 'sort':
      return {
        defectLabel:
          reportType === 'electrical' ? 'Undersøges nærmere' : 'Mulige skader',
        defectLevel:
          reportType === 'electrical' ? 'examined-further' : 'possible',
        sortingOrder: 4,
      };
    case 'xxx':
      return {
        defectLabel:
          reportType === 'electrical' ? 'Undersøges nærmere' : 'TEMP',
        defectLevel:
          reportType === 'electrical' ? 'examined-further' : 'possible',
        sortingOrder: 4,
      };
    case null:
      return {
        defectLabel:
          reportType === 'electrical' ? 'Undersøges nærmere' : 'TEMP',
        defectLevel:
          reportType === 'electrical' ? 'examined-further' : 'possible',
        sortingOrder: 4,
      };
  }
};

import { combineReducers } from 'redux';

const id = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const authenticated = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({ id, authenticated });

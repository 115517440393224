/**
 *
 * @param props
 * @param callback
 * @param preApprove
 * @returns {boolean|*}
 */
export const validatePropertyOwner = (props, callback, preApprove) => {
  let sessionToken = window.localStorage.getItem("sessionToken");
  if(!sessionToken && preApprove){
    sessionToken = "#h!s1sn0tarealt9kenth1sbutaf8ke1gsjwhf4s";
  }
  if (props) {
    let url;
    if (props.bfeId) {
      preApprove = true;
      // const bfeId = (props.bfeId + "").padStart(10, "0");
      // url = `https://erst-api.virk.dk/boligejer-integrationer/mithuseftersyn/validerEjerskab/${props.token}/${bfeId}`;
    } else if (props.municipalityId && props.cadastralId) {
      const municipalityId = (props.municipalityId + "").padStart(4, '0');
      const cadastralId = (props.cadastralId + "").padStart(6, '0');
      url = `https://erst-api.virk.dk/boligejer-integrationer/mithuseftersyn/validerEjerskab/${props.token}/${municipalityId}/${cadastralId}`;
    }

    /*Slet props debug nedenstående condition når bolig ejer godkender. Fra her til  */
    if (props.debug) {
      const unnecessaryDiv = document.createElement("div");
      const unnecessaryStyle = 'background: #0bbbb5; color: #00; font-size:16px; padding:5px;';
      console.group('%c Boligejer validation', unnecessaryStyle);
      unnecessaryDiv.style = unnecessaryStyle;
      unnecessaryDiv.innerHTML = 'DEBUG Verbose: <br> Kalder API med følgende URL: ' + url;
      console.log('%c Kalder API med følgende URL: ' + url, unnecessaryStyle);

      fetch(url)
        .then(responseFromAPI => {
          console.log('%c API Status: Modtaget', unnecessaryStyle);
          unnecessaryDiv.innerHTML += '<br> API Status: Modtaget';
          console.log('%c API Response', unnecessaryStyle, {responseFromAPI});
        })
        .catch(error => {
          console.log('%c API Status: Fejl', unnecessaryStyle);
          unnecessaryDiv.innerHTML += '<br> API Status: Fejl';
          console.log('%c API Response: ', unnecessaryStyle, {error});
        })
        .finally(() => {
          console.groupEnd();
          document.body.appendChild(unnecessaryDiv);
          return preApprove && !callback ? true : callback(true);
        });
    } else {
      return preApprove && !callback ? true : callback(true);
    }
    /* Her ^^^ */

    if (props.token && sessionToken && sessionToken.length === 40) {
      return !callback ? true : callback(true);
    } else {
      fetch(url)
        .then(status => {
          if (status == true) {
            return !callback ? true : callback(true);
          } else {
            return !callback ? false : callback(false);
          }
        })
        .catch(error => {
          console.warn(error);
          return !callback ? false : callback(false);
        })
    }
  }
}

export const getQuery = (key) => {
  const queries = window.location.search.replace('?', '').split('&');
  const keyValue = key + '=';
  const query = queries.find((key) => {
    return key.indexOf(keyValue) === 0;
  });

  if (query) {
    return decodeURIComponent(query.replace(keyValue, ''));
  }

  return null;
};

import React from 'react';

import './style.scss';
import FPImage from '@assets/fp_img.png';

const FrontPage = ({ match }) => {
  return (
    <div className="dr-frontpage">
      <div>
        <div className="flex flex-center fp-header-img">
          <img src={FPImage} alt="" />
        </div>

        <div className="fp-container">
          <h1>Velkommen til Mithuseftersyn.dk.</h1>

          <p>
            På denne side kan du få indblik i en tilstandsrapport eller
            elinstallationsrapport for en boligejendom.
          </p>

          <p>
            Tilstandsrapporten og elinstallationsrapporten tilhører husejeren,
            og derfor er der ikke fri adgang til rapporterne.
          </p>

          <p>
            Du kan få adgang til tilstandsrapporter og elinstallationsrapporter
            og dermed til Mithuseftersyn.dk på to måder:
          </p>
          <p>
            - Er du ejer af en ejendom, kan du logge på med NemID via
            Boligejer.dk. Søg på adressen.
          </p>
          <p>
            - Er du interesseret i at se rapporterne for en ejendom, du ikke
            ejer, skal du have et link fra ejeren eller ejendomsmægleren.
          </p>
          <div className="fp-footer">
            <p>
              Venlig hilsen,
              <br />
              Sikkerhedsstyrelsen
            </p>
            <p>
              <a href="https://www.sik.dk/">www.sik.dk</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;

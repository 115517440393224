import { combineReducers, applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import data from './data';
import user from './user/reducers';
import { config } from '@/AppConfig';

const composeEnhanced =
  config.app.nodeEnv === 'production' ? compose : composeWithDevTools;

const middleware = [thunk];

const rootReducer = combineReducers({
  data,
  user,
});

const defaultState = {
  user: {
    id: true,
    authenticated: true,
  },
};

export const store = createStore(
  rootReducer,
  defaultState,
  composeEnhanced(applyMiddleware(...middleware)),
);

import React, { useMemo, useCallback } from 'react';

import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

const FilterSelectMenuProps = {
  id: 'dr-filter-select',
  classes: {
    paper: 'dr-filter-select-paper',
  },
};

const FilterActions = ({
  initDefects = [],
  filters = {},
  locationFilterArray = [],
  evaluations = [],
  changeFilters,
}) => {
  const {
    locationFilterRenderValue,
    evaluationFilterRenderValue,
    handleLocationChange,
  } = useComponent({
    initDefects,
    evaluations,
    locationFilterArray,
    filters,
    changeFilters,
  });

  return (
    <div className="row">
      <div className="col-md-4 col-sm-6">
        <FormControl className="filter-item-wrapper">
          <Select
            // docs at https://material-ui.com/api/select/
            multiple
            displayEmpty
            className="main-select-element"
            classes={{
              root: 'select-root-element',
              select: 'select-input-element',
              icon: 'select-input-icon',
            }}
            autoWidth
            input={<Input />}
            value={filters.selectedLocations}
            renderValue={locationFilterRenderValue}
            onChange={handleLocationChange}
            MenuProps={FilterSelectMenuProps}
            onOpen={() => changeFilters({ locationsOpened: true })}
            onClose={() => changeFilters({ locationsOpened: false })}
            open={filters.locationsOpened || false}
          >
            <MenuItem disabled value="">
              <em>Sorter ud fra placering</em>
            </MenuItem>
            {(locationFilterArray || []).map((el) => {
              // array of all MenuItems
              let displayItems = [];

              // render select option for category
              let displayCategory = (
                <MenuItem
                  key={el.locationCategory}
                  value={el.locationCategory}
                  className="category-select-item select-item"
                >
                  <Checkbox
                    color="primary"
                    checked={
                      filters.selectedLocations.indexOf(el.locationCategory) >
                      -1
                    }
                  />
                  <ListItemText
                    classes={{ primary: 'select-item-text' }}
                    primary={el.locationCategory + ' (alle)'}
                  />
                </MenuItem>
              );
              displayItems.push(displayCategory);

              // render select options for locations
              let displayCategoryLocations = el.locations.map((location) => {
                return (
                  <MenuItem
                    key={location.locationId}
                    value={location.locationId}
                    className="select-item"
                  >
                    <Checkbox
                      color="primary"
                      checked={
                        filters.selectedLocations.indexOf(location.locationId) >
                        -1
                      }
                    />
                    <ListItemText
                      classes={{ primary: 'select-item-text' }}
                      primary={
                        el.locationCategory + ': ' + location.locationName
                      }
                    />
                  </MenuItem>
                );
              });
              displayItems.push(displayCategoryLocations);

              return displayItems;
            })}
          </Select>
        </FormControl>
      </div>

      <div className="col-md-4 col-sm-6">
        <FormControl className="filter-item-wrapper">
          <Select
            multiple
            displayEmpty
            className="main-select-element"
            classes={{
              root: 'select-root-element',
              select: 'select-input-element',
              icon: 'select-input-icon',
            }}
            autoWidth
            input={<Input />}
            value={filters.selectedEvaluations}
            renderValue={evaluationFilterRenderValue}
            onChange={(event) =>
              changeFilters({
                selectedEvaluations: event.target.value,
                evaluationsOpened: !filters.evaluationsOpened,
              })
            }
            MenuProps={FilterSelectMenuProps}
            onOpen={() => changeFilters({ evaluationsOpened: true })}
            onClose={() => changeFilters({ evaluationsOpened: false })}
            open={filters.evaluationsOpened || false}
          >
            <MenuItem disabled value="">
              <em>Sorter ud fra vurdering</em>
            </MenuItem>
            {(evaluations || []).map((evaluation) => {
              return (
                <MenuItem
                  key={evaluation.defectLevel}
                  value={evaluation.defectLevel}
                  className="select-item"
                >
                  <Checkbox
                    color="primary"
                    checked={
                      filters.selectedEvaluations.indexOf(
                        evaluation.defectLevel,
                      ) > -1
                    }
                  />
                  <ListItemText
                    classes={{ primary: 'select-item-text' }}
                    primary={evaluation.defectLabel}
                  />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default React.memo(FilterActions);

const useComponent = ({
  initDefects = [],
  evaluations = [],
  locationFilterArray,
  filters = {},
  changeFilters,
}) => {
  /**
   * Render the selected locations.
   *
   * @param {array} selected The selected locations array.
   *
   * @return {string}
   */
  const locationFilterRenderValue = useCallback(
    (selected) => {
      // if selected is empty, show placeholder
      if (selected.length === 0) {
        return <span>Sorter ud fra placering</span>;
      } else {
        let selectedValueNames = [];

        selected.map((el) => {
          if (typeof el === 'string') {
            selectedValueNames.push(el + ' (alle)');
          } else {
            let locationLabel = '';
            (initDefects || []).forEach(function(defect) {
              if (
                defect.location.locationId === el &&
                locationLabel.length === 0
              ) {
                // locationLabel = defect.location.locationCategory + ': ' + defect.location.locationName;
                locationLabel = defect.location.locationName;
              }
            });
            selectedValueNames.push(locationLabel);
          }
        });

        return selectedValueNames.join(', ');
      }
    },
    [initDefects],
  );

  /**
   * Render function for selected evaluations.
   *
   * @param {array} selected Selected evaluations.
   *
   * @return {string}
   */
  const evaluationFilterRenderValue = useCallback(
    (selected) => {
      // if selected is empty, show placeholder
      if (selected.length === 0) {
        return <span>Sorter ud fra vurdering</span>;
      } else {
        let selectedValueNames = [];
        selected.map((el) => {
          evaluations.forEach(function(evaluation) {
            if (evaluation.defectLevel === el) {
              selectedValueNames.push(evaluation.defectLabel);
            }
          });
        });
        return selectedValueNames.join(', ');
      }
    },
    [evaluations],
  );

  /**
   * Handle the location filter changes.
   *
   * @param {Event} event The event from the Select component.
   *
   * @return {void}.
   */
  const handleLocationChange = useCallback(
    (event) => {
      const selectedLocations = event.target.value;
      const latestSelectedLocation =
        selectedLocations[selectedLocations.length - 1];
      let filterOutIds = [];

      // If the latest location is a category string, then deselect all the children locations.
      if (typeof latestSelectedLocation === 'string') {
        // If user has selected entire floor, then deselect the rooms inside.
        filterOutIds = (
          selectedLocations
            .filter((v) => typeof v === 'string')
            .map((v) =>
              (
                locationFilterArray.find((x) => x.locationCategory === v)
                  .locations || []
              ).map((x) => x.locationId),
            )
        );
        filterOutIds = [filterOutIds].reduce( (a, b) => a.concat(b), [])

      } else {
        // If the latest selected is a locationId, then deselect the parent category.
        const findLocationParent = locationFilterArray.find((parent) => {
          return parent.locations.some(
            (x) => x.locationId === latestSelectedLocation,
          );
        });

        if (findLocationParent && findLocationParent.locationCategory) {
          filterOutIds.push(findLocationParent.locationCategory);
        }
      }

      return changeFilters({
        selectedLocations: selectedLocations.filter(
          (id) => filterOutIds.indexOf(id) === -1,
        ),
        locationsOpened: !filters.locationsOpened,
      });
    },
    [locationFilterArray, filters, changeFilters],
  );

  return useMemo(() => {
    return {
      locationFilterRenderValue,
      evaluationFilterRenderValue,
      handleLocationChange,
    };
  }, [
    locationFilterRenderValue,
    evaluationFilterRenderValue,
    handleLocationChange,
  ]);
};

import React from 'react';
import { createMarkup } from '@/helpers';

// Components
// material-ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ContentWithVocabulary from '@/components/FilterTable/ContentWithVocabulary';

// css
import './TableWidget.scss';

const TableWidget = (props) => {
  return (
    <div className="dr-table">
      <Paper className="dr-table-paper">
        <Table>
          <TableHead>
            <TableRow>
              {props.table.tableHead.map((col, index) => {
                col.name = col.name.replace(`m2`, `m<sup>2</sup>`);

                return (
                  <TableCell
                    className="head-cell"
                    key={index}
                    align={col.numeric ? 'right' : 'inherit'}
                  >
                    <div dangerouslySetInnerHTML={createMarkup(col.name)} />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.table.tableBody.map((row, index) => {
              return (
                <TableRow key={index}>
                  {row.map((col, index) => {
                    return (
                      <TableCell
                        className="body-cell"
                        key={index}
                        align={col.numeric ? 'right' : 'inherit'}
                      >
                        <ContentWithVocabulary
                          className="text"
                          text={col.value}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default TableWidget;

import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {withRouter} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import {config} from '@/AppConfig';
import axios from '@/api';

// custom
import OverviewPage from './OverviewPage';
import ReportsCommentsPage from './ReportsCommentsPage';
import FAQPage from './FAQPage';
import BuildingsInReportPage from './BuildingsInReportPage';
import DisassembledPage from './DisassembledPage';
import AboutHousePage from './AboutHousePage';
import AttentionPage from './AttentionPage';
import GenericPage from './GenericPage';

import Page404 from '../components/Page404';
import Page401 from '../components/Page401';
import Loader from '../components/Loader/Loader';

import {
  prepareForMunicipalityCadastralReport,
  prepareForBfeReport,
  setMunicipalityId,
  setCadastralId,
  setBfeId,
  setBasePath,
} from '../store/data/property/creators';

import {loadWords} from '../store/data/vocab/creators';

import {
  RootPath,
  FAQPath,
  BuildingsInReportPath,
  ReportCommentsPath,
  DisassembledPath,
  AboutHousePath,
  AttentionPath,
  GenericPath,
  BfePath,
  FAQBfePath,
  BuildingsInReportBfePath,
  ReportCommentsBfePath,
  DisassembledBfePath,
  AboutHouseBfePath,
  AttentionBfePath,
  GenericBfePath,
  NO_ACCESS_ERROR,
  NOT_FOUND_ERROR,
} from '../constants';

/**
 *
 * @param match
 * @param getSelectedItemData
 * @returns {JSX.Element}
 * @constructor
 */
const ReportRouter = ({match, getSelectedItemData}) => {
  const dispatch = useDispatch();

  // selectors
  const user = useSelector(({user}) => user);
  const {
    municipalityId,
    cadastralId,
    bfeId,
    isFetching,
    loaded,
    noProperty,
    basePath,
    error,
    data: propertyApiData,
  } = useSelector(({data}) => data.property);

  // effects
  useEffect(() => {
    // set ids in global state
    if (match.path.indexOf('/bfe/') > -1) {
      const { bfeId } = match.params;
      dispatch(setBfeId(bfeId));
    } else {
      const {municipalityId, cadastralId} = match.params;
      dispatch(setMunicipalityId(municipalityId));
      dispatch(setCadastralId(cadastralId));
    }
  }, []);

  useEffect( () => {
    if (municipalityId && cadastralId) {
      // load report from current ids
      dispatch(prepareForMunicipalityCadastralReport());
      // load vocabulary words as well
      dispatch(loadWords());
    }
  }, [municipalityId, cadastralId]);

  useEffect( () => {
    if (bfeId) {
      // load report from current ids
      dispatch(prepareForBfeReport());
      // load vocabulary words as well
      dispatch(loadWords());
    }
  }, [bfeId]);

  return (
    <Fragment>
     {!noProperty && (
        <Switch>
          <Route
            path={RootPath}
            exact
            render={(props) => <OverviewPage {...props} />}
          />
          <Route path={FAQPath} exact component={FAQPage}/>
          <Route
            path={BuildingsInReportPath}
            exact
            component={BuildingsInReportPage}
          />
          <Route
            path={ReportCommentsPath}
            exact
            component={ReportsCommentsPage}
          />
          <Route path={DisassembledPath} exact component={DisassembledPage}/>
          <Route path={AboutHousePath} exact component={AboutHousePage}/>
          <Route path={AttentionPath} exact component={AttentionPage}/>
          <Route path={GenericPath} exact component={GenericPage}/>

          <Route
            path={BfePath}
            exact
            render={(props) => <OverviewPage {...props} />}
          />
          <Route path={FAQBfePath} exact component={FAQPage}/>
          <Route
            path={BuildingsInReportBfePath}
            exact
            component={BuildingsInReportPage}
          />
          <Route
            path={ReportCommentsBfePath}
            exact
            component={ReportsCommentsPage}
          />
          <Route path={DisassembledBfePath} exact component={DisassembledPage}/>
          <Route path={AboutHouseBfePath} exact component={AboutHousePage}/>
          <Route path={AttentionBfePath} exact component={AttentionPage}/>
          <Route path={GenericBfePath} exact component={GenericPage}/>
          <Route component={Page404}/>
        </Switch>
      )}
      {noProperty && !isFetching && loaded && error === NOT_FOUND_ERROR && (
        <Page404/>
      )}
      {noProperty && !isFetching && loaded && error === NO_ACCESS_ERROR && (
        <Page401/>
      )}
      {isFetching && (
        <div className="app-wrapper-loader">
          <Loader/>
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(ReportRouter);

const apiEndpoint = (process.env.API_URL || "$API_URL")
const imageEndpoint = (process.env.APP_URL || "$APP_URL") + '/storage/';
const nodeEnv = process.env.NODE_ENV || "development";

export const config = {
  app: {
    nodeEnv
  },
  api: {
    apiEndpoint,
    imageEndpoint,
    videoEndpoint: apiEndpoint + '/video-embed',
    words: apiEndpoint + '/words',
    promoted: apiEndpoint + '/words/promoted',
    word: apiEndpoint + '/words/',
    faqs: apiEndpoint + '/faqs',
    page: apiEndpoint + '/pages/',
    property: apiEndpoint + '/property/',
    reportPdf: apiEndpoint + '/reports/',
    downloadDefectsTable: apiEndpoint + '/download-filtered-defects-table',
  },
};

import React from 'react';

// css
import './Loader.scss';

const loader = (props) => {
  return (
    <div className="dr-loader">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default loader;

import axios from '@/api';
import {config} from '@/AppConfig';
import {getQuery} from '@/helpers/query';

import {NO_ACCESS_ERROR, NOT_FOUND_ERROR} from '@/constants';

import {
  SET_MUNICIPALITY_ID,
  SET_CADASTRAL_ID,
  SET_BFE_ID,
  SET_BASE_PATH,
  PROPERTY_REQUEST,
  PROPERTY_ERROR,
  PROPERTY_SUCCESS,
} from './actions';
import {validatePropertyOwner} from "../../../hooks/boligejerValidator";

export const setMunicipalityId = (id) => ({
  type: SET_MUNICIPALITY_ID,
  value: id,
});

export const setCadastralId = (id) => ({
  type: SET_CADASTRAL_ID,
  value: id,
});

export const setBfeId = (id) => ({
  type: SET_BFE_ID,
  value: id,
});

export const setBasePath = (path) => ({
  type: SET_BASE_PATH,
  value: path,
});

export const prepareForMunicipalityCadastralReport = () => (dispatch, getState) => {
  const property = getState().data.property;
  const municipalityId = parseInt(property.municipalityId, 10);
  const cadastralId = parseInt(property.cadastralId, 10);
  const token = getQuery('token') || sessionStorage.getItem('token');
  const debugParam = getQuery('debug') ? true : false;

  if (token && token !== sessionStorage.getItem('token')) {
    sessionStorage.setItem('token', token);
  }

  dispatch({type: PROPERTY_REQUEST});

  const validationProps = {
    token: token, municipalityId: municipalityId, cadastralId: cadastralId, debug:debugParam
  };

  validatePropertyOwner(validationProps, validated => {
    dispatch(loadNewRapport(token, validated, { municipalityId: municipalityId, cadastralId: cadastralId }))
  }, true);

};

export const prepareForBfeReport = () => (dispatch, getState) => {
  const property = getState().data.property;
  const bfeId = parseInt(property.bfeId, 10);
  const token = getQuery('token') || sessionStorage.getItem('token');
  const debugParam = !!getQuery('debug');

  if (token && token !== sessionStorage.getItem('token')) {
    sessionStorage.setItem('token', token);
  }

  dispatch({type: PROPERTY_REQUEST});

  const validationProps = {
    token: token, bfeId: bfeId, debug:debugParam
  };

  validatePropertyOwner(validationProps, validated => {
    dispatch(loadNewRapport(token, validated, { bfeId: bfeId }))
  }, true);
};
export const loadNewRapport = (token, validated, propertyIds) => (dispatch) => {
  if(!validated){
    dispatch({type: PROPERTY_ERROR, message: NO_ACCESS_ERROR});
    return;
  }
  const municipalityId = propertyIds.municipalityId ?? false;
  const cadastralId = propertyIds.cadastralId ?? false;
  const bfeId = propertyIds.bfeId ?? false;
  let apiUrl = `${config.api.apiEndpoint}/v2/bfe/${bfeId}`;
  if (bfeId === false) {
    apiUrl = `${config.api.apiEndpoint}/municipalities/${municipalityId}/properties/${cadastralId}`;
  }
  const params = {};
  params.token = token;
  axios
    .get(apiUrl, {params})
    .then((res) => {
      const {data} = res;
      let basePath = `/bfe/${bfeId}`;
      if (bfeId === false) {
        basePath = `/report/${municipalityId}/${cadastralId}`;
      }
      dispatch(setBasePath(basePath));
      dispatch({type: PROPERTY_SUCCESS, data});
      window.localStorage.setItem("sessionToken", token);
    })
    .catch((err) => {
      if (err.response && err.response.data) {
        const message = err.response.data.message.toLowerCase() || '';
        dispatch({type: PROPERTY_ERROR, message});
      } else {
        dispatch({type: PROPERTY_ERROR, message: NOT_FOUND_ERROR});
      }
      console.error(err);
    });
}

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
// custom
import ButtonPrimary from '@/components/ButtonPrimary/ButtonPrimary';

import { getBasePath } from '@/store/selectors';

// css
import './style.scss';

const Page401 = (props) => {
  const basePath = useSelector(getBasePath);
  const linkPath = useMemo(() => basePath || '/', [basePath]);

  return (
    <div className="dr-page-404">
      <div className="container">
        <div className="main-text">
          <span className="text-404">401</span>
          <span>Ingen adgang</span>
        </div>
        <div className="sub-text">
          Du står ikke registreret som ejer af den ejendom, som du prøver at åbne i mithuseftersyn.dk
        </div>
        <ButtonPrimary link={linkPath} type="navlink">
          Til forside
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default Page401;

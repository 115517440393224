import React, { useMemo, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getSelectedWord } from '@/store/data/vocab/creators';

const ContentWithVocabulary = ({ className = '', text = '' }) => {
  const dispatch = useDispatch();
  const getWord = (id) => dispatch(getSelectedWord(id));

  const ref = useRef(null);

  const filteredText = useMemo(() => {
    return text;
  }, [text]);

  useEffect(() => {
    // get all words on the page
    const allWords = ref.current.querySelectorAll('.vocabulary-word');
    // add clickListener to each of them
    for (let i = 0; i < allWords.length; i++) {
      const word = allWords[i];

      word.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        // we get id of the word based on 'data-word-id' attribute
        let wordId = word.getAttribute('data-word-id');
        // function to get word data from API and shop pop-up
        getWord(wordId);
      });
    }
  });

  return (
    <div
      ref={ref}
      className={`content-with-vocabulary ${className}`}
      dangerouslySetInnerHTML={{ __html: filteredText }}
    ></div>
  );
};

export default React.memo(ContentWithVocabulary);

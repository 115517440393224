import { combineReducers } from 'redux';

import {
  PAGE_REQUEST,
  PAGE_SUCCESS,
  PAGE_ERROR,
  SET_CURRENT_PATH,
} from './actions';

const data = (state = null, action) => {
  switch (action.type) {
    case PAGE_SUCCESS:
      return action.data;
    case PAGE_ERROR:
    case PAGE_REQUEST:
      return null;
    default:
      return state;
  }
};

const path = (state = '', action) => {
  switch (action.type) {
    case SET_CURRENT_PATH:
      return action.value;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case PAGE_REQUEST:
      return true;
    case PAGE_ERROR:
    case PAGE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case PAGE_SUCCESS:
      return true;
    case PAGE_REQUEST:
    case PAGE_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  path,
  loaded,
  isFetching,
});

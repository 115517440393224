import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
//global
import { config } from '../../AppConfig';
// material-ui
import Dialog from '@material-ui/core/Dialog';
// helpers
import { createMarkup } from './../../helpers';

// css
import './VocabularyModal.scss';

// assets
import closeIcon from '@assets/icons/modal-close-icon.svg';

import {
  getCurrentVocabularyWord,
  getVocabularyModalState,
} from '@/store/selectors';

import { setModalState } from '@/store/data/vocab/creators';

const VocabularyModal = () => {
  const dispatch = useDispatch();

  const word = useSelector(getCurrentVocabularyWord);
  const modalOpen = useSelector(getVocabularyModalState);

  const closeModal = () => {
    dispatch(setModalState(false));
  };

  const videoSrc =
    word && word.videoId
      ? 'https://www.youtube.com/embed/' + word.videoId
      : null;

  return (
    word && (
      <Dialog
        className="dr-vocabulary-modal"
        open={modalOpen}
        onClose={closeModal}
        maxWidth={false}
        scroll="body"
        classes={{ paper: 'paper-wrapper' }}
      >
        <div className="vocabulary-modal-header">
          <div onClick={closeModal} className="close-modal-icon-wrapper">
            <img src={'/' + closeIcon} className="close-modal-icon" />
          </div>
          {word.label}
        </div>

        <div className="vocabulary-modal-body">
          <div className="word">{word.label}</div>
          <div
            className="word-definition"
            dangerouslySetInnerHTML={createMarkup(word.wordDefinition)}
          />

          {word.image ? (
            <div className="word-images-wrapper">
                  <img
                    className="word-image"
                    src={config.api.imageEndpoint + word.image}
                  />
            </div>
          ) : null}

          {word.videoId && (
            <div>
              <div className="video-title">Se mere her:</div>
              <iframe
                className="video-iframe"
                width="100%"
                src={videoSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
        </div>
      </Dialog>
    )
  );
};

export default VocabularyModal;

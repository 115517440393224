import React from 'react';

// Components
// material-ui
import Button from '@material-ui/core/Button';

// css
import './ButtonFilterPrimary.scss';

function ButtonFilterPrimary(props) {
  let rootClasses = 'dr-button-filter-primary';
  if (props.isActive) {
    rootClasses = 'dr-button-filter-primary active';
  }
  return (
    <div className="dr-button-filter-primary-wrapper">
      <Button
        variant="outlined"
        component="div"
        color="primary"
        onClick={props.clicked}
        classes={{ root: rootClasses }}
      >
        {props.children}
      </Button>
    </div>
  );
}

export default ButtonFilterPrimary;

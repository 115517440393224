import React, { Component } from 'react';
import { useSelector } from 'react-redux';

// Components
// global
import Aux from './../../hoc/Auxiliary/Auxiliary';
// material-ui
// custom
import Card from './../../components/Card/Card';
import CardGoogleView from './../../components/CardGoogleView/CardGoogleView';

// css
import './CardsBlock.scss';

import { getPropertyApiData, getBasePath } from '@/store/selectors';

const CardsBlock = ({ currentPage }) => {
  const propertyApiData = useSelector(getPropertyApiData);
  const basePath = useSelector(getBasePath);

  const propertyTypeData = propertyApiData.type;
  const propertyTypeDescription =
    propertyTypeData && propertyTypeData.description
      ? propertyTypeData.description.slice(0, 100).trim() + '...'
      : '';

  // e.g. https://www.google.com/maps/place/Nørregade+63,+6700+Esbjerg
  const googleMapLink =
    'https://www.google.com/maps/place/' +
    propertyApiData.address1 +
    ', ' +
    propertyApiData.postcode;

  const cards = [
    propertyApiData.reports.house && {
      id: 'om-hustypen',
      type: 'default',
      tagName: 'Om hustypen',
      title: propertyTypeData.type,
      description: propertyTypeDescription,
      link: basePath + '/rapport-info/om-hustypen',
    },
    {
      type: 'google-street-view',
      link: googleMapLink,
    },
    {
      id: 'bygninger-i-tilstandsrapporten',
      type: 'reportBuildings',
      tagName: 'Tilstandsrapport',
      title: 'Bygninger i tilstandsrapporten',
      description: '',
      link: basePath + '/rapport-info/bygninger-i-tilstandsrapporten',
    },
    {
      type: 'default',
      tagName: 'Tilstandsrapport',
      title: 'Opmærksomhedspunkter',
      description:
        'Se, hvad du generelt skal være opmærksom på ved denne hustype.',
      link: basePath + '/rapport-info/opmaerksomhedspunkter',
    },
    {
      id: 'anmaerkninger-i-rapporterne',
      type: 'default',
      tagName: 'Generelt',
      title: 'Fejl og skader i rapporterne',
      description:
        'Vi har samlet alle fejl og skader fra eleftersynet og bygningseftersynet i to overskuelige tabeller.',
      link: basePath + '/rapport-info/anmaerkninger-i-rapporterne',
    },
    {
      type: 'electricityInstallations',
      tagName: 'Elinstallationsrapport',
      title: 'Hvilke elinstallationer er blevet adskilt?',
      link:
        basePath + '/rapport-info/hvilke-elinstallationer-er-blevet-adskilt',
    },
    {
      id: 'huseftersynsordningen',
      type: 'buildingsInspectionSchemes',
      tagName: 'Generelt',
      title: 'Om huseftersynsordningen',
      description: '',
      link: basePath + '/rapport-info/om-huseftersynsordningen',
    },
    {
      id: 'hvad-gennemgaas-ved-et-eftersyn',
      type: 'default',
      tagName: 'Generelt',
      title: 'Hvad gennemgås ved eftersynene?',
      description:
        'Læs om, hvad den bygningssagkyndige og elinstallatørvirksomheden gennemgår ved huseftersynet.',
      link: basePath + '/rapport-info/hvad-gennemgaas-ved-et-eftersyn',
    },
  ];

  const columnClass =
    currentPage === 'overview'
      ? 'col-md-6 dr-card-block__equal-height'
      : 'col-md-6 col-xl-4 dr-card-block__equal-height';

  return (
    <div className="dr-card-block">
      <div className="row">
        {cards
          .filter((card) => card && card.id !== currentPage)
          .map((el, index) => {
            // get link of the card (bygninger-i-tilstandsrapporten) and remove first charachter which is '/'
            let cardLink = el.link.substring(1);

            if (
              el.type === 'google-street-view' &&
              currentPage === 'overview'
            ) {
              return (
                <div key={index} className={columnClass}>
                  <CardGoogleView link={el.link} />
                </div>
              );
            }
            if (el.type !== 'google-street-view' && currentPage !== cardLink) {
              return (
                <div key={index} className={columnClass}>
                  <Card
                    cardType={el.type}
                    tagName={el.tagName}
                    title={el.title}
                    description={el.description}
                    link={el.link}
                  />
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default CardsBlock;

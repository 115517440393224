import React from 'react';

// css
import './CardGoogleView.scss';

// assets
import googleViewCardBackgroundImg from '@assets/card-block-component/google-view-card-background.png';
import arrowIcon from '@assets/icons/arrow-forward-white.svg';

function cardGoogleView(props) {
  var cardStyles = {
    backgroundImage: 'url(/' + googleViewCardBackgroundImg + ')',
  };

  return (
    <a
      target="_blank"
      href={props.link}
      className="dr-card-google-view-item"
      style={cardStyles}
    >
      <div className="content-wrapper">
        <div className="heading">
          Se boligen på <br /> Google Street View
        </div>
        <img src={'/' + arrowIcon} className="arrow-icon" />
      </div>
    </a>
  );
}

export default cardGoogleView;

import { combineReducers } from 'redux';

import {
  WORDS_REQUEST,
  WORDS_SUCCESS,
  WORDS_ERROR,
  SET_VOCABULARY_WORD,
  SET_MODAL_STATE,
} from './actions';

const words = (state = [], action) => {
  switch (action.type) {
    case WORDS_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case WORDS_REQUEST:
      return true;
    case WORDS_SUCCESS:
    case WORDS_ERROR:
      return false;
    default:
      return state;
  }
};

const currentWord = (state = null, action) => {
  switch (action.type) {
    case SET_VOCABULARY_WORD:
      return action.word;
    default:
      return state;
  }
};

const modalOpen = (state = false, action) => {
  switch (action.type) {
    case SET_MODAL_STATE:
      return action.value;
    default:
      return state;
  }
};

export default combineReducers({
  words,
  isFetching,
  currentWord,
  modalOpen,
});

import React, { useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '@/components/PageHeader';
import PageSection from '@/components/PageSection';
import CardsBlock from '@/components/CardsBlock';
import Loader from '@/components/Loader/Loader';

import { replaceVocabularyWords } from '@/helpers';

import {
  getBasePath,
  getTransformedDisassemblyTable,
  getWords,
} from '@/store/selectors';

import { pageText } from './constants';

const DisassembledPage = ({ match }) => {
  const basePath = useSelector(getBasePath);
  const disassemblyTable = useSelector(getTransformedDisassemblyTable);
  const words = useSelector(getWords);

  const transformedPageText = useMemo(
    () => replaceVocabularyWords(words, pageText),
    [words, pageText],
  );
  const currentPageLink = useMemo(() => match.path.split('/').pop(), [match]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dr-content-page">
      <div className="container">
        <PageHeader
          title="Adskilte elinstallationer"
          description="Se hvilke elinstallationer der er blevet adskilt ved gennemgangen."
        />
        <PageSection text={transformedPageText} />

        <PageSection table={disassemblyTable} />

        <CardsBlock basePath={basePath} currentPage />
      </div>
    </div>
  );
};

export default DisassembledPage;

// These should be removed when BFE is always used
export const RootPath = '/report/:municipalityId/:cadastralId';
export const FAQPath = RootPath + '/spoergsmaal-og-svar';
export const BuildingsInReportPath =  RootPath + '/rapport-info/bygninger-i-tilstandsrapporten';
export const ReportCommentsPath =  RootPath + '/rapport-info/anmaerkninger-i-rapporterne';
export const DisassembledPath =  RootPath + '/rapport-info/hvilke-elinstallationer-er-blevet-adskilt';
export const AboutHousePath = RootPath + '/rapport-info/om-hustypen';
export const AttentionPath = RootPath + '/rapport-info/opmaerksomhedspunkter';
export const GenericPath = RootPath + '/rapport-info/:page';

export const BfePath = '/bfe/:bfeId';
export const FAQBfePath = BfePath + '/spoergsmaal-og-svar';
export const BuildingsInReportBfePath =  BfePath + '/rapport-info/bygninger-i-tilstandsrapporten';
export const ReportCommentsBfePath =  BfePath + '/rapport-info/anmaerkninger-i-rapporterne';
export const DisassembledBfePath =  BfePath + '/rapport-info/hvilke-elinstallationer-er-blevet-adskilt';
export const AboutHouseBfePath = BfePath + '/rapport-info/om-hustypen';
export const AttentionBfePath = BfePath + '/rapport-info/opmaerksomhedspunkter';
export const GenericBfePath = BfePath + '/rapport-info/:page';

export const NO_ACCESS_ERROR = 'no access';
export const NOT_FOUND_ERROR = 'not found';

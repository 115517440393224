import React, { useCallback, useMemo, useState } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import Aux from './../../hoc/Auxiliary/Auxiliary';
import ContentWithVocabulary from './ContentWithVocabulary';

// assets
// building icons
import criticalDamageIcon from '@assets/icons/damage-icons/critical-damage-icon.svg';
import seriousDamageIcon from '@assets/icons/damage-icons/serious-damage-icon.svg';
import lessSeriousDamageIcon from '@assets/icons/damage-icons/less-serious-damage-icon.svg';
import potentialDamageIcon from '@assets/icons/damage-icons/potential-damage-icon.svg';
// electricity icons
import riskOfChockIcon from '@assets/icons/electricity-problems-icons/risk-of-chock-icon.svg';
import riskOfFireIcon from '@assets/icons/electricity-problems-icons/risk-of-fire-icon.svg';
import illegalInstallationsIcon from '@assets/icons/electricity-problems-icons/illegal-installations-icon.svg';
import examinedFurtherIcon from '@assets/icons/electricity-problems-icons/examined-further-icon.svg';

const FilterTableResults = ({
  reportType,
  tableRows = [],
  defects,
  getSelectedItemData,
}) => {
  const {
    order,
    orderBy,

    getDefectIcon,
    sortTableHandler,
    stableSort,
    getSortingFunction,
  } = useComponent({ reportType });

  return (
    <div className="dr-table">
      <Paper className="dr-table-paper">
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {tableRows.map((row) => {
                return row.sortable === true ? (
                  <TableCell
                    className="head-cell"
                    key={row.id}
                    align={row.numeric ? 'right' : 'inherit'}
                    padding={row.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Tooltip
                      title="Sorter"
                      placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={sortTableHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell
                    className="head-cell"
                    key={row.id}
                    align={row.numeric ? 'right' : 'inherit'}
                    padding={row.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    {row.label}
                  </TableCell>
                );
              }, this)}
            </TableRow>
          </TableHead>

          <TableBody>
            {stableSort(defects, getSortingFunction(order, orderBy)).map(
              (el, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={el.id || index}
                  >
                    <TableCell component="th" scope="row" className="body-cell">
                      <div className="location-wrapper">
                        <div className="location-category">
                          {el.location.locationCategory}
                        </div>
                        {el.location.locationName}
                      </div>
                    </TableCell>
                    <TableCell className="body-cell">
                      {el.evaluation && (
                        <img
                          className="evaluation-icon"
                          src={getDefectIcon(el.evaluation.defectLevel)}
                        />
                      )}
                    </TableCell>
                    <TableCell className="body-cell">
                      <ContentWithVocabulary
                        className="body-cell-content"
                        text={el.damage}
                        getSelectedItemData={getSelectedItemData}
                      />
                    </TableCell>
                    {reportType != 'electricity' ? (
                      <Aux>
                        <TableCell className="body-cell">
                          <ContentWithVocabulary
                            className="body-cell-content"
                            text={el.risk}
                            getSelectedItemData={getSelectedItemData}
                          />
                        </TableCell>
                        <TableCell className="body-cell">
                          <ContentWithVocabulary
                            className="body-cell-content"
                            text={el.note}
                            getSelectedItemData={getSelectedItemData}
                          />
                        </TableCell>
                      </Aux>
                    ) : null}
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default React.memo(FilterTableResults);

const useComponent = ({ reportType }) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(null);

  /**
   * Returns img src string based on report type and defectLevel
   *
   * @param defectLevel - defect level of evaluation - one of ['less-serious', 'serious']
   * @returns string
   */
  const getDefectIcon = useCallback(
    (defectLevel) => {
      let icon = null;

      if (!defectLevel) {
        return null;
      }

      if (reportType === 'building') {
        switch (defectLevel) {
          case 'critical':
            icon = criticalDamageIcon;
            break;
          case 'serious':
            icon = seriousDamageIcon;
            break;
          case 'less-serious':
            icon = lessSeriousDamageIcon;
            break;
          case 'possible':
            icon = potentialDamageIcon;
            break;
        }
      } else if (reportType === 'electricity') {
        switch (defectLevel) {
          case 'risk-of-chock':
            icon = riskOfChockIcon;
            break;
          case 'risk-of-fire':
            icon = riskOfFireIcon;
            break;
          case 'illegal-installations':
            icon = illegalInstallationsIcon;
            break;
          case 'examined-further':
            icon = examinedFurtherIcon;
            break;
        }
      }

      return '/' + icon;
    },
    [reportType],
  );

  /**
   * Recieves single row.id and what set the order and orderBy state
   * tell the code, what to sort on..
   * Sorts only on ['location', 'evaluation'], the rest is ignored
   *
   * @param property - what we sort on? - evaluation, location from rows - {id: 'location', numeric: false, disablePadding: false, label: 'Placering'},
   * @returns function
   */
  const sortTableHandler = useCallback(
    (newOrderBy) => {
      return () => {
        let newOrder = 'desc';

        if (orderBy === newOrderBy && order === 'desc') {
          newOrder = 'asc';
        }

        if (['location', 'evaluation'].includes(newOrderBy)) {
          setOrder(newOrder);
          setOrderBy(newOrderBy);
        }
      };
    },
    [order, orderBy, setOrder, setOrderBy],
  );

  /**
   * Do sorting of array and returns sorted items..
   *
   * @param array - all items
   * @param compareFunction - sorting function
   * @returns []
   */
  const stableSort = useCallback((array, compareFunction) => {
    // if compareFunction is null, jist return array
    if (compareFunction !== null) {
      const stabilizedThis = array.map((el, index) => [el, index]);

      // sort array
      stabilizedThis.sort((a, b) => {
        // compare elements
        const order = compareFunction(a[0], b[0]);

        if (order !== 0) return order;
        // if elements are equal, compare indexes
        return a[1] - b[1];
      });

      return stabilizedThis.map((el) => el[0]);
    } else {
      return array;
    }
  }, []);

  /**
   * Sorting function
   * we sort diferently depending on orderBy
   *
   * @param a
   * @param b
   * @param orderBy - what to sort on
   * @returns of of [-1, 1, 0]
   */
  const desc = useCallback((a, b, orderBy) => {
    switch (orderBy) {
      case 'location':
        if (b[orderBy].locationCategory === a[orderBy].locationCategory) {
          if (b[orderBy].locationName < a[orderBy].locationName) {
            return -1;
          }
          if (b[orderBy].locationName > a[orderBy].locationName) {
            return 1;
          }
        } else {
          if (b[orderBy].locationCategory < a[orderBy].locationCategory) {
            return -1;
          }
          if (b[orderBy].locationCategory > a[orderBy].locationCategory) {
            return 1;
          }
        }
        return 0;
        break;

      case 'evaluation':
        if (b[orderBy].sortingOrder < a[orderBy].sortingOrder) {
          return -1;
        }
        if (b[orderBy].sortingOrder > a[orderBy].sortingOrder) {
          return 1;
        }
        return 0;
        break;
    }
  }, []);
  /**
   * Returns sorting function desc or -desc based on order
   *
   * @param order
   * @param orderBy - what to sort on
   * @returns func
   */
  const getSortingFunction = useCallback(
    (order, orderBy) => {
      if (orderBy !== null) {
        return order === 'desc'
          ? (a, b) => desc(a, b, orderBy)
          : (a, b) => -desc(a, b, orderBy);
      } else {
        return null;
      }
    },
    [desc],
  );

  return useMemo(() => {
    return {
      order,
      orderBy,

      getDefectIcon,
      sortTableHandler,
      stableSort,
      getSortingFunction,
    };
  }, [
    order,
    orderBy,

    getDefectIcon,
    sortTableHandler,
    stableSort,
    getSortingFunction,
  ]);
};

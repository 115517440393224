import ax from '@/api';
import { config } from '@/AppConfig';
import { store } from '@/store';

export default {
  setPublicState: (isPublic = false) => {
    const property = store.getState().data.property;

    const municipalityId = parseInt(property.municipalityId, 10);
    const cadastralId = parseInt(property.cadastralId, 10);

    return ax.put(
      config.api.apiEndpoint + `/municipalities/${municipalityId}/properties/${cadastralId}`,
      { is_public: isPublic },
      {
        headers: {
          'boligejer-token': property.data.boligejer_token,
        },
      },
    );
  },
};

import React from 'react';

// helpers
import { createMarkup } from './../../helpers';

// css
import './AttentionNotes.scss';

// assets
import AsbestosIcon from '@assets/icons/attention-notes-icons/asbestos.png';
import BasementIcon from '@assets/icons/attention-notes-icons/basement.png';
import DoorsWindowsIcon from '@assets/icons/attention-notes-icons/doors-windows.png';
import FloorIcon from '@assets/icons/attention-notes-icons/floor.png';
import FoundationIcon from '@assets/icons/attention-notes-icons/foundation.png';
import GutterIcon from '@assets/icons/attention-notes-icons/gutter.png';
import GutterHiddenIcon from '@assets/icons/attention-notes-icons/gutter-hidden.png';
import CeilingIcon from '@assets/icons/attention-notes-icons/loft.png';
import RadonIcon from '@assets/icons/attention-notes-icons/radon.png';
import RoofIcon from '@assets/icons/attention-notes-icons/roof.png';
import WallsInnerIcon from '@assets/icons/attention-notes-icons/walls-inner.png';
import WallsOuterIcon from '@assets/icons/attention-notes-icons/walls-outer.png';
import WetroomIcon from '@assets/icons/attention-notes-icons/wetroom.png';

import ContentWithVocabulary from '@/components/FilterTable/ContentWithVocabulary';

function getAttentionIcon(iconName) {
  let icon = null;

  switch (iconName) {
    case 'asbestos':
      icon = AsbestosIcon;
      break;
    case 'basement':
      icon = BasementIcon;
      break;
    case 'doors-windows':
      icon = DoorsWindowsIcon;
      break;
    case 'floor':
      icon = FloorIcon;
      break;
    case 'foundation':
      icon = FoundationIcon;
      break;
    case 'gutter':
      icon = GutterIcon;
      break;
    case 'gutter-hidden':
      icon = GutterHiddenIcon;
      break;
    case 'loft':
      icon = CeilingIcon;
      break;
    case 'radon':
      icon = RadonIcon;
      break;
    case 'roof':
      icon = RoofIcon;
      break;
    case 'walls-inner':
      icon = WallsInnerIcon;
      break;
    case 'walls-outer':
      icon = WallsOuterIcon;
      break;
    case 'wetroom':
      icon = WetroomIcon;
      break;
  }

  return '/' + icon;
}

function AttentionNotes(props) {
  return (
    <div className="attention-notes-block">
      {props.attentionNotes.map((el, index) => {
        return (
          <div className="attention-note-item clearfix" key={index}>
            <img className="icon" src={getAttentionIcon(el.icon)} />
            <div className="main-info-wrapper">
              <span className="title">{el.title}</span>{' '}
              <span className="building-name">({el.buildingName})</span>
              <ContentWithVocabulary className="text" text={el.text} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AttentionNotes;

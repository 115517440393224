import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
// custom
import PageHeader from '@/components/PageHeader';
import PageSubHeader from '@/components/PageSubHeader';
import CardsBlock from '@/components/CardsBlock';
import TableWidget from '@/components/TableWidget/TableWidget';
// helpers
import { createMarkup } from '@/helpers';

// css
import './BuildingsInReportPage.scss';

import {
  getBasePath,
  getPropertyApiData,
  getExcludedBuildings,
} from '@/store/selectors';

const createTable = (house, electrical) => {
  const buildings = house.buildings || electrical.buildings;
  if (!buildings) return [];

  return buildings.map((building) => [
    {
      value: building.name,
      numeric: false,
    },
    {
      value: building.year,
      numeric: true,
    },
    {
      value: building.area,
      numeric: true,
    },
    {
      value: building.floors,
      numeric: true,
    },
    {
      value: building.attic,
      numeric: true,
    },
    {
      value: building.living,
      numeric: true,
    },
  ]);
};

const BuildingsInReportPage = ({ location }) => {
  const basePath = useSelector(getBasePath);
  const property = useSelector(getPropertyApiData);

  const excludedBuildings = useSelector(getExcludedBuildings);

  useEffect(() => {
    // scroll window up, when navigation through different links
    window.scrollTo(0, 0);
  }, []);

  const { house, electrical } = property.reports;

  // set buildingsInfoTable tableBody data
  const tableBody = createTable(house, electrical);

  const currentPageLink = location.pathname.split('/').pop();

  const buildingsInfoTable = {
    heading: 'Oplysninger om ejendommens bygninger',
    table: {
      tableHead: [
        {
          name: 'Bygning',
          numeric: false,
        },
        {
          name: 'Opført',
          numeric: true,
        },
        {
          name: 'Bebygget areal m2',
          numeric: true,
        },
        {
          name: 'Etager udover kælder',
          numeric: true,
        },
        {
          name: 'Udnyttet tagetage m2',
          numeric: true,
        },
        {
          name: 'Brutto etageareal m2',
          numeric: true,
        },
      ],
      tableBody,
    },
  };

  return (
    <div className="dr-buildings-in-report-page">
      <div className="container">
        <PageHeader title={BuildingsInReportPage.options.pageTitle} />

        <PageSubHeader uppercase>{buildingsInfoTable.heading}</PageSubHeader>

        <TableWidget table={buildingsInfoTable.table} />

        <div className="building-desc-text">
          <div>
            <h3>Bemærkninger</h3>
            <p>
              Oversigten viser bygninger på ejendommen, der er omfattet af
              tilstandsrapporten. Den bygningssagkyndige kan undtage konkrete
              bygninger fra eftersynet, hvis de har ringe byggeteknisk eller
              brugsmæssig værdi. Derfor kan der være bygninger og bygningsdele,
              som ikke er besigtiget.
            </p>

            <p>
              Hvis du vil vide, hvordan du er dækket af ejerskifteforsikringen i
              forhold til undtagelserne, kan du læse mere i dine
              forsikringsbetingelser.
            </p>
          </div>
          <div>
            <h3>Bygninger, som den bygningssagkyndige har undtaget.</h3>
            <p>
              Alle bygninger er som udgangspunkt en del af eftersynet. Men den
              bygningssagkyndige kan undtage konkrete bygninger på grund af
              ringe byggeteknisk eller brugsmæssig værdi.
            </p>
          </div>
          {false && excludedBuildings.map((building, index) => {
            // temporarily hide excluded buildings
            return (
              <div key={index}>
                <h3>{building.building}</h3>
                <p>{building.comments}</p>
              </div>
            );
          })}
        </div>

        <CardsBlock currentPage={currentPageLink} />
      </div>
    </div>
  );
};

BuildingsInReportPage.options = {
  pageTitle: 'Bygninger i tilstandsrapporten',
};

export default BuildingsInReportPage;

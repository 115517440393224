import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';

// Components
// global
import axios from 'axios';
import { config } from '../../AppConfig';
// material-ui
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// custom
import Aux from '@/hoc/Auxiliary/Auxiliary';
import PageHeader from '@/components/PageHeader';
import PageSubHeader from '@/components/PageSubHeader';
import Tabs from '@/components/Tabs/Tabs';
import Vocabulary from '@/components/Vocabulary/Vocabulary';
import Loader from '@/components/Loader/Loader';
import ContentWithVocabulary from '@/components/FilterTable/ContentWithVocabulary';

import {
  createMarkup,
  setClickVocabularyWordHandler,
  replaceVocabularyWords,
} from '@/helpers';

// css
import './FAQPage.scss';

import { getWords } from '@/store/selectors';

function FAQPage() {
  const words = useSelector(getWords);

  const [activeTab, setActiveTab] = useState(0);
  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [buildingFaq, setBuildingFaq] = useState([]);
  const [electricityFaq, setElectricityFaq] = useState([]);

  const source = axios.CancelToken.source();

  useEffect(() => {
    // component mount
    axios
      .get(config.api.faqs, {
        cancelToken: source.token,
      })
      .then((res) => {
        const faqs = res.data;
        initVocabulary(words, faqs);
      })
      .catch((err) => {
        console.error(config.api.faqs, err);
      });

    // component unmount
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);

  // handles Tab click
  const tabClickHandler = (e, value) => {
    setActiveTab(value);
  };

  // handles accordion click
  // if item is expanded, we set activeAccordionItem to items ID..
  const accordionChangeHandler = (id) => {
    return (event, expanded) => {
      setActiveAccordionItem(expanded ? id : false);
    };
  };

  /**
   * Vocabulary functionality
   *
   * Function, which looks through all FAQ questions, finds all words from the vocabulary and changes 'word' to '<span class="vocabulary-word" data-word-id="' + word.id + '">' + word.label + '</span>', which allows us to style it and add Js functionality to make words clickable. On click, we open pop-up with fetched data..
   *
   * for each work in vocabulary, we replace both 'word' and 'Word'..
   * we may call this function few times, as axios get() words asynchronous and we do not know, when we will get data back..
   *
   * @param words
   * @param faqs
   */
  const initVocabulary = (words, faqs) => {
    if (words !== null && faqs !== null) {
      faqs.map((faq) => {
        faq.questions.map((question) => {
          question.answer = replaceVocabularyWords(words, question.answer);
        });
      });

      // update state with correctly rendered FAQ questions,
      // using vocabulary words markup.
      // when state is updated, useEffect will run,
      // where we can call setClickVocabularyWordHandler

      setTabs([{ label: faqs[0].title }, { label: faqs[1].title }]);
      setBuildingFaq(faqs[0].questions);
      setElectricityFaq(faqs[1].questions);
      setDataLoaded(true);
    }
  };

  return (
    <div className="dr-faq-page">
      <div className="container">
        <PageHeader title="Spørgsmål og svar" />

        {dataLoaded ? (
          <Aux>
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              tabClicked={tabClickHandler}
              variant="fullWidth"
            />

            <div className="tab-content">
              <div className="row">
                <div className="col-lg-9">
                  {activeTab === 0 ? (
                    <Fragment>
                      <PageSubHeader>
                        Spørgsmål og svar til tilstandsrapporten
                      </PageSubHeader>
                      <div className="dr-accordion-block">
                        {buildingFaq.map((el, index) => {
                          return (
                            <Accordion
                              className="accordion-item"
                              key={el.id}
                              expanded={activeAccordionItem === el.id}
                              onChange={accordionChangeHandler(el.id)}
                            >
                              <AccordionSummary
                                classes={{ root: 'accordion-item-heading' }}
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <div>{el.question}</div>
                              </AccordionSummary>
                              <AccordionDetails className="accordion-item-description">
                                <ContentWithVocabulary text={el.answer} />
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </div>
                    </Fragment>
                  ) : null}

                  {activeTab === 1 ? (
                    <Fragment>
                      <PageSubHeader>
                        Spørgsmål og svar til elinstallationsrapporten
                      </PageSubHeader>
                      <div className="dr-accordion-block">
                        {electricityFaq.map((el, index) => {
                          return (
                            <Accordion
                              className="accordion-item"
                              key={el.id}
                              expanded={activeAccordionItem === el.id}
                              onChange={accordionChangeHandler(el.id)}
                            >
                              <AccordionSummary
                                classes={{ root: 'accordion-item-heading' }}
                                expandIcon={<ExpandMoreIcon />}
                              >
                                <div>{el.question}</div>
                              </AccordionSummary>
                              <AccordionDetails className="accordion-item-description">
                                <ContentWithVocabulary text={el.answer} />
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </div>
                    </Fragment>
                  ) : null}
                </div>

                {words !== null ? (
                  <div className="col-lg-3">
                    <Vocabulary />
                  </div>
                ) : null}
              </div>
            </div>
          </Aux>
        ) : (
          <div className="faq-page-loader">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQPage;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
// custom
import ButtonPrimary from '@/components/ButtonPrimary/ButtonPrimary';

import { getBasePath } from '@/store/selectors';

// css
import './style.scss';

const Page404 = (props) => {
  const basePath = useSelector(getBasePath);
  const linkPath = useMemo(() => basePath || '/', [basePath]);

  return (
    <div className="dr-page-404">
      <div className="container">
        <div className="main-text">
          <span className="text-404">404</span>
          <span>Siden findes ikke</span>
        </div>
        <div className="sub-text">
          Der foreligger ikke en eleftersyns - og en tilstandsrapport udarbejdet
          efter 31/9-2020 på den pågældende ejendom
        </div>
        <ButtonPrimary link={linkPath} type="navlink">
          Til forside
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default Page404;

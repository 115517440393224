import React, { Component } from 'react';
import Slider from 'react-slick';
import './Gallery.scss';

class Gallery extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="dr-gallery">
        <Slider {...settings}>
          {this.props.items.map((el, index) => {
            return (
              <div key={index} className="gallery-item">
                <img
                  className="gallery-image"
                  src="https://via.placeholder.com/750x422?text=I+Need+Space C/O https://placeholder.com/"
                  alt=""
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default Gallery;

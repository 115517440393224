import React from 'react';

// Components
// material-ui
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// custom
import ButtonTransparentPrimary from './../../components/ButtonTransparentPrimary/ButtonTransparentPrimary';
import EvaluationDetailsModal from './../../components/EvaluationDetailsModal/EvaluationDetailsModal';
// helpers
import { createMarkup } from './../../helpers';

// css
import './DamageDescription.scss';


function damageDescription(props) {
  let blockData = props.data;

  return (
    <div className="damage-description-block">
      <Accordion className="block-panel grey-bg">
        <AccordionSummary
          classes={{ root: 'damage-description-heading' }}
          expandIcon={<ExpandMoreIcon />}
        >
          <div>{blockData.blockHeading}</div>
        </AccordionSummary>

        <AccordionDetails  className="damage-description-content">
          <div className="row damage-description-boxes-wrapper">
            {blockData.blocks.map((el, index) => {
              return (
                <div className="col-sm-6 col-lg-3 column" key={index}>
                  <div className="damage-description-box">
                    <img className="box-icon" src={'/' + el.icon} />
                    <div className="box-heading">{el.title}</div>
                    <div
                      className="box-description"
                      dangerouslySetInnerHTML={createMarkup(el.description)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <ButtonTransparentPrimary
            type="button"
            action={props.showEvaluationDetailsModalHandler}
          >
            Læs om vurderingssystemet
          </ButtonTransparentPrimary>
        </AccordionDetails >
      </Accordion >
      <EvaluationDetailsModal
        reportType={props.reportType}
        open={props.evaluationDetailsModalOpen}
        modalClosed={props.closeEvaluationDetailsModalHandler}
      />
    </div>
  );
}

export default damageDescription;

import axios from '@/api';
import { config } from '@/AppConfig';

import {
  WORDS_REQUEST,
  WORDS_SUCCESS,
  WORDS_ERROR,
  SET_VOCABULARY_WORD,
  SET_MODAL_STATE,
} from './actions';

export const loadWords = () => (dispatch) => {
  dispatch({ type: WORDS_REQUEST });
  axios
    .get(config.api.words)
    .then((res) => {
      dispatch({ type: WORDS_SUCCESS, data: res.data });
    })
    .catch((err) => {
      dispatch({ type: WORDS_ERROR });
      console.error(config.api.words, err);
    });
};

export const getSelectedWord = (id) => (dispatch) => {
  axios.get(config.api.word + id).then((res) => {
    const word = res.data;

    dispatch({
      type: SET_VOCABULARY_WORD,
      word: {
        label: word.word,
        wordDefinition: word.definition,
        image: word.image && word.image,
        videoId: word.video_embed_code,
      },
    });

    dispatch({
      type: SET_MODAL_STATE,
      value: true,
    });
  });
};

export const setModalState = (value) => (dispatch) => {
  dispatch({ type: SET_MODAL_STATE, value });
};

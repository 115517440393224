import React from 'react';

// Components
// material-ui
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// custom
import ButtonPrimary from './../ButtonPrimary/ButtonPrimary';

// css
import './Card.scss';

// assets
import reportBuildingsIcon from '@assets/card-block-component/report-buildings-icon.svg';
import buildingsInspectionSchemesIcon from '@assets/card-block-component/buildings-inspection-schemes-icon.svg';
import electricityInstallationsIcon from '@assets/card-block-component/electricity-installations-icon.svg';

function CardComponent({ cardType, description, title, tagName, link }) {
  let content = null;

  if (cardType === 'default') {
    if (description && description !== '') {
      content = (
        <Typography component="p" className="description">
          {description}
        </Typography>
      );
    }
  } else {
    // set corresponding image
    let img = null;
    let imgClass = 'img-responsive card-img';
    switch (cardType) {
      case 'reportBuildings':
        img = reportBuildingsIcon;
        break;
      case 'buildingsInspectionSchemes':
        img = buildingsInspectionSchemesIcon;
        break;
      case 'electricityInstallations':
        img = electricityInstallationsIcon;
        imgClass = 'img-responsive card-img electricity-installations';
        break;
    }
    content = <img src={'/' + img} className={imgClass} />;
  }

  return (
    <Card className="dr-card-item">
      <CardContent className="main-content">
        <div className="card-tag">{tagName}</div>
        <Typography
          className="heading"
          gutterBottom
          variant="h5"
          component="h2"
        >
          {title}
        </Typography>
        {content}
      </CardContent>
      <CardContent className="btn-wrapper">
        <ButtonPrimary type="navlink" link={link} fullWidth>
          Se mere
        </ButtonPrimary>
      </CardContent>
    </Card>
  );
}

export default CardComponent;

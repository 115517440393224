import React from 'react';

// Components
// material-ui
import Paper from '@material-ui/core/Paper';
import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';

// css
import './Tabs.scss';

const Tabs = ({ tabs, activeTab, variant, tabClicked, centered = false }) => {
  const tabClasses = {
    root: centered ? 'tab-item centered' : 'tab-item',
  };

  return (
    <div className="dr-tabs-wrapper">
      <Paper square className="paper-tabs-wrapper">
        <MTabs
          className="dr-tabs"
          value={activeTab}
          indicatorColor="primary"
          onChange={tabClicked}
          centered={centered}
          variant={variant}
        >
          {tabs.map((tab, index) => {
            return <MTab classes={tabClasses} key={index} label={tab.label} />;
          })}
        </MTabs>
      </Paper>
    </div>
  );
}

export default Tabs;

import React, { Fragment, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PageHeader from '@/components/PageHeader';
import PageSection from '@/components/PageSection';
import CardsBlock from '@/components/CardsBlock';
import Loader from '@/components/Loader/Loader';

import { fetchPageData, setCurrentPath } from '@/store/data/page/creators';

import {
  getCurrentPath,
  getPageData,
  getTransformedPageData,
} from '@/store/selectors';

import './style.scss';

const GenericPage = ({ match }) => {
  const dispatch = useDispatch();
  const page = useSelector(getTransformedPageData);
  const currentPageLink = useSelector(getCurrentPath);
  const isFetching = useSelector((state) => state.data.page.isFetching);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(setCurrentPath(match.params.page));
    dispatch(fetchPageData());
  }, [match]);

  return (
    <div className="dr-content-page">
      <div className="container">
        {isFetching ? (
          <div className="content-page-loader">
            <Loader />
          </div>
        ) : (
          <Fragment>
            <PageHeader title={page.title} description={page.description} />
            {page?.sections?.map((section) => (
              <PageSection key={section.id} {...section} />
            ))}
          </Fragment>
        )}

        <CardsBlock currentPage={currentPageLink} />
      </div>
    </div>
  );
};

export default GenericPage;

/**
 * Helper functions - can be used on every page
 *
 * can be included as:
 *
 * // helpers
 * import { setClickVocabularyWordHandler, replaceVocabularyWords } from "./../../helpers";
 */

/**
 * Allows to output html with tags from a string to the JSX(React component)
 * without this, JSX will output html tags as a string "<div>text</div>"
 *
 * Can be used like <div className="test" dangerouslySetInnerHTML={ createMarkup(props.testString) }></div>
 * @param html
 * @returns row html code inside React Component
 */
export const createMarkup = (html) => {
  return { __html: html };
};

/**
 * Vocabulary functionality
 *
 * we set click on all words in the document with 'vocabulary-word' class.. as we get these data from backend, we can not use default react onClick function..
 */
let lastVocabularyWordTimeout = null;
export const setClickVocabularyWordHandler = (getSelectedItemData) => {
  if (lastVocabularyWordTimeout) {
    clearTimeout(lastVocabularyWordTimeout);
  }
  // we need to set Timeout for these functionality as before we call this function, we deal with changing DOM elements and in this code we rely on these changed elements.. So browser javascript environment have to be able to change that DOM before we start to manipulate with new markup..
  lastVocabularyWordTimeout = setTimeout(function() {
    // get all words on the page
    let allWords = document.querySelectorAll('.vocabulary-word');
    // add clickListener to each of them
    for (let i = 0; i < allWords.length; i++) {
      const word = allWords[i];
      const newNode = word.cloneNode(true);

      newNode.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        // we get id of the word based on 'data-word-id' attribute
        let wordId = word.getAttribute('data-word-id');
        // function to get word data from API and shop pop-up
        getSelectedItemData(wordId);
      });

      word.parentNode.insertBefore(newNode, word);
      word.parentNode.removeChild(word);
    }
  }, 50);
};

/**
 * function, which receives all vocabulary words and a string, which have to be changed..
 * It looks into the string and replace all vocabulary words with new markup so instead of "House" we get "<span class="vocabulary-word" data-word-id="4">House</span>"
 * we need to change all words - in the beginnig of the string, in the middle after space, but not ***word or word***.
 *
 * @todo we need to remove <div></div> wrapper on a string.. so it will come a clean string from API..
 *
 * @param words - array of strings
 * @param string - string
 * @returns string
 */
export const replaceVocabularyWords = (words, string = '') => {
  words.forEach(function(word) {
    // RegExp examples
    // https://stackoverflow.com/questions/2116558/fastest-method-to-replace-all-instances-of-a-character-in-a-string
    // https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string-in-javascript
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters

    // first we change all words which comes after ' '(space)
    // $1, $2 is a groups in regex expression - $1 is space (( ){1}) $2 - is word itself $3 is "en" group, $4 is space or "."
    let regExp = new RegExp(
      '( ){1}(' + word.label + ')(en|et|erne|ere|er|e){0,1}( |\\.|\\,)',
      'ig',
    );
    string = string.replace(
      regExp,
      '$1<span class="vocabulary-word" data-word-id="' +
        word.id +
        '">$2$3</span>$4',
    );

    // after all other words is changed, we handle the last case - if word is in the beginning of the string..
    regExp = new RegExp(
      '^' + word.label + '(en|et|erne|ere|er|e){0,1}( |\\.|\\,)',
    );
    string = string.replace(
      regExp,
      '<span class="vocabulary-word" data-word-id="' +
        word.id +
        '">' +
        word.label +
        '$1</span>$2',
    );
  });

  return string;
};

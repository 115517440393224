import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getBasePath, getPropertyOverview } from '@/store/selectors';

// helpers
import { createMarkup } from './../../helpers';

// css
import './PageHeader.scss';

// assets
import backArrowIcon from '@assets/icons/arrow-back-black.svg';

const PageHeader = ({ title, description }) => {
  const { address1: address, postcode, city } = useSelector(
    getPropertyOverview,
  );
  const basePath = useSelector(getBasePath);

  return (
    <header className="dr-page-header">
      <div className="page-header-navigation">
        <Link to={basePath}>
          <img
            src={'/' + backArrowIcon}
            alt="back-arrow-button"
            className="back-button"
          />
        </Link>
        {address && postcode && city ? (
          <span className="page-header-meta">
            {address + ', ' + postcode + ', ' + city}
          </span>
        ) : null}
      </div>
      <h1 className="page-header-title">{title}</h1>
      <div
        className="page-header-description"
        dangerouslySetInnerHTML={createMarkup(description)}
      />
    </header>
  );
};

export default PageHeader;

import React from 'react';
import { useSelector } from 'react-redux';

// Components
// global
import { Link } from 'react-router-dom';
// material-ui
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
// custom
import NavLinks from './../NavLinks/NavLinks';

// css
import './Header.scss';

// assets
import closeIcon from '@assets/icons/drawer-close-icon.svg';
// building icons
import logoIcon from '@assets/icons/logo-icon.svg';
import logoutIcon from '@assets/icons/logout-icon.svg';

import { getBasePath } from '@/store/selectors';

const Header = (props) => {
  const currentBaseReportPath = useSelector(getBasePath);

  return !currentBaseReportPath ? (
    <div>
      <header className="dr-header">
        <div className="logo-nav-wrapper">
          <Link to={currentBaseReportPath || '/'} className="logo">
            <img className="logo-icon" src={'/' + logoIcon} />
          </Link>
        </div>
      </header>
    </div>
  ) : (
    <div>
      <header className="dr-header">
        <div className="logo-nav-wrapper">
          <Link to={currentBaseReportPath || '/'} className="logo">
            <img className="logo-icon" src={'/' + logoIcon} />
          </Link>
          <ul className="main-nav-desktop d-none d-lg-flex">
            <NavLinks currentBaseReportPath={currentBaseReportPath} />
          </ul>
        </div>

        <IconButton
          className="menu-btn d-lg-none"
          onClick={props.toggleMobileMenu(true)}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>

        <a className="logout-link d-none d-lg-flex">
          <img className="logout-icon" src={'/' + logoutIcon} />
          <span className="logout-text">Log ud</span>
        </a>
      </header>

      <Drawer
        anchor="right"
        open={props.mobileMenuOpened}
        onClose={props.toggleMobileMenu(false)}
        classes={{ modal: 'dr-drawer', paper: 'paper-wrapper' }}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={props.toggleMobileMenu(false)}
          onKeyDown={props.toggleMobileMenu(false)}
        >
          <div
            onClick={props.toggleMobileMenu(false)}
            className="close-drawer-icon-wrapper"
          >
            <img src={'/' + closeIcon} className="close-drawer-icon" />
          </div>

          <ul className="main-nav-mobile">
            <NavLinks currentBaseReportPath={currentBaseReportPath} />
          </ul>

          <div className="logout-link">
            <img className="logout-icon" src={'/' + logoutIcon} />
            <span className="logout-text">Log ud</span>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;

import { createSelector } from 'reselect';

import { replaceVocabularyWords } from '@/helpers';

export const getPropertyApiData = (state) => state.data.property.data;
export const getBasePath = (state) => state.data.property.basePath;
export const getMunicipalityId = (state) => state.data.property.municipalityId;
export const getBfeId = (state) => state.data.property.bfeId;
export const getWords = (state) => state.data.vocab.words;
export const getCurrentVocabularyWord = (state) => state.data.vocab.currentWord;
export const getVocabularyModalState = (state) => state.data.vocab.modalOpen;
export const getCurrentPath = (state) => state.data.page.path;
export const getPageData = (state) => state.data.page.data;

export const getVideoEmbedCode = (state) => state.data.video.embedCode;

export const getPropertyOverview = createSelector(getPropertyApiData, (data) =>
  data
    ? {
        id: data.id,
        address1: data.address1,
        address2: data.address2,
        postcode: data.postcode,
        city: data.city,
        description: data.description,
        area: data.area,
        imageURL: data.image_url,
      }
    : null,
);

export const getExcludedBuildings = createSelector(
  getPropertyApiData,
  (data) => {
    return data.reports.house.exceptions;
  },
);

export const getTransformedPageData = createSelector(
  getPageData,
  getWords,
  (data, words) => {
    if (!data) return {};
    return {
      ...data,
      sections: data.sections.map((section) => ({
        ...section,
        text: replaceVocabularyWords(words, section.text),
      })),
    };
  },
);

export const getDisassemblyNotes = createSelector(
  getPropertyApiData,
  (data) => data?.reports?.electrical?.disassembled || [],
);

export const getDisassemblyTable = createSelector(
  getDisassemblyNotes,
  (notes) => {
    const body = notes.map((note) => [
      { value: note.location.building, numeric: false },
      {
        value: `${note.location.floor}: ${note.location.room}`,
        numeric: false,
      },
      {
        value: note.text,
        numeric: false,
      },
    ]);

    return {
      tableHead: [
        { name: 'Bygning' },
        { name: 'Placering' },
        { name: 'Adskilte dele' },
      ],
      tableBody: body,
    };
  },
);

export const getTransformedDisassemblyTable = createSelector(
  getDisassemblyTable,
  getWords,
  (table, words) => {
    const transformedTable = table.tableBody.map((row) =>
      row.map((col) => ({
        ...col,
        value: replaceVocabularyWords(words, col.value),
      })),
    );
    return {
      ...table,
      tableBody: transformedTable,
    };
  },
);

export const getAttentionNotes = createSelector(getPropertyApiData, (data) =>
  data?.reports?.house?.buildings
    ?.reduce((acc, building) => {
      const attentionNotes = building.attention.map((note) => ({
        ...note,
        buildingName: building.name,
      }));
      return [...acc, ...attentionNotes];
    }, [])
    .filter(
      (note, index, self) =>
        // create stringified value from buildingName and note title
        // to compare to current note buildingName and title so we can filter
        // duplicate entries. a little wonky but it works
        self
          .map((note) => note.buildingName + note.title)
          .indexOf(note.buildingName + note.title) === index,
    ),
);

export const getTransformedAttentionNotes = createSelector(
  getAttentionNotes,
  getWords,
  (notes, words) =>
    notes.map((note) => ({
      ...note,
      text: replaceVocabularyWords(words, note.text),
    })),
);

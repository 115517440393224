import React from 'react';

// Components
// material-ui
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
// custom
import ButtonPrimary from './../ButtonPrimary/ButtonPrimary';

// css
import './LoginModal.scss';

function loginModal(props) {
  return (
    <Dialog
      className="dr-login-modal"
      open={props.open}
      maxWidth={false}
      scroll="body"
      classes={{ paper: 'paper-wrapper' }}
    >
      <div className="message">
        Du logger nu ind på Mit Huseftersyn, som er din digitale visning af
        tilstandsrapporten og elinstallationsrapporten. Du skal være opmærksom
        på, at den digitale version ikke er de juridisk bindende rapporter.
        Rapporterne finder du som pdf, og du kan downloade dem via de to links
        på denne side samt på boligejer.dk.
        <br />
        <br />
        Når du køber hus, er det altid de komplette rapporter, du skal forholde
        dig til. De indeholder information om alt fra hustypebeskrivelse og
        oplysninger om elinstallationens funktionsdygtighed til generel
        information om huseftersynsordningen og ejerskifteforsikringen.
      </div>

      {props.showErrorMessage ? (
        <div className="error-message">
          Check venligst en checkbox for at fortsætte
        </div>
      ) : null}

      <div className="checkbox-btn-wrapper">
        <div className="message-checkbox-wrapper">
          <Checkbox
            className="message-checkbox"
            checked={props.messageAgreed}
            onChange={props.changeCheckbox}
            color="primary"
          />
          <div
            className="message-agree-text"
            onClick={props.changeLabelCheckbox}
          >
            Ja, det er forstået
          </div>
        </div>
        <ButtonPrimary type="button" action={props.modalClosed}>
          Videre
        </ButtonPrimary>
      </div>
    </Dialog>
  );
}

export default loginModal;

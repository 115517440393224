import React from 'react';

// Components
// global
import { NavLink } from 'react-router-dom';
// custom
import Aux from '../../hoc/Auxiliary/Auxiliary';

const NavLinks = (props) => {
  if (
    props.currentBaseReportPath !== null &&
    props.currentBaseReportPath !== false
  ) {
    return (
      <Aux>
        <li>
          <NavLink to={props.currentBaseReportPath} exact>
            Overblik
          </NavLink>
        </li>
        <li>
          <NavLink
            to={{
              pathname:
                props.currentBaseReportPath +
                '/rapport-info/anmaerkninger-i-rapporterne',
            }}
          >
            Fejl og skader i rapporterne
          </NavLink>
        </li>
        <li>
          <NavLink to={`${props.currentBaseReportPath}/spoergsmaal-og-svar`}>
            Spørgsmål og svar
          </NavLink>
        </li>
      </Aux>
    );
  } else {
    return null;
  }
};

export default NavLinks;

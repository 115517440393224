import React from 'react';

// css
import './PageSubHeader.scss';

const pageSubHeader = (props) => {
  return (
    <h2
      className={`dr-page-subheader ${
        props.uppercase ? 'dr-page-uppercase' : ''
      }`}
    >
      {props.children}
    </h2>
  );
};

export default pageSubHeader;

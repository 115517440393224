import React from 'react';

// Components
// custom
import CardTransparent from './../../components/CardTransparent/CardTransparent';

// css
import './SidebarReportsCardBlock.scss';

const SidebarReportsCardBlock = ({ reports }) => {
  if (!reports || reports.length <= 0) return null;

  return (
    <div className="dr-sidebar-reports-card-block">
      <div className="dr-sidebar-card-block">
        <div className="sidebar-heading">Download Rapporter</div>
        <div className="row">
          <div className="col-lg-12 col-sm-6">
            {reports?.buildingInspection?.downloadLink && (
              <CardTransparent
                cardType="downloadReport"
                report={reports.buildingInspection}
              />
            )}
          </div>
          <div className="col-lg-12 col-sm-6">
            {reports?.electricityInspection?.downloadLink && (
              <CardTransparent
                cardType="downloadReport"
                report={reports.electricityInspection}
              />
            )}
          </div>
        </div>
      </div>

      <div className="dr-sidebar-card-block">
        <div className="sidebar-heading">Har du spørgsmål til rapporterne?</div>
        <div className="row">
          <div className="col-lg-12 col-sm-6">
            <CardTransparent
              cardType="reportCompanyInfo"
              report={reports.buildingInspection}
            />
          </div>
          <div className="col-lg-12 col-sm-6">
            <CardTransparent
              cardType="reportCompanyInfo"
              report={reports.electricityInspection}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarReportsCardBlock;

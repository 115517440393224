import React, { useEffect, useCallback, useState } from 'react';

import Loader from '@/components/Loader/Loader';
import axios from '@/api';
import { config } from '@/AppConfig';

const VideoPlayer = ({ match, url }) => {
  const [isFetching, setFetching] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [embedCode, setEmbedCode] = useState(null);

  const loadVideo = useCallback(() => {
    setFetching(true);
    axios
      .get(config.api.videoEndpoint, {
        params: {
          url,
          format: 'json',
        },
      })
      .then((res) => {
        if (res.data.status !== 'ok') {
          console.log('error', res.data);
        } else {
          setEmbedCode(res.data.embed.embed_code);
        }
        setLoaded(true);
        setFetching(false);
      })
      .catch((err) => {
        console.log('error', err);
        setFetching(false);
      });
  }, [setLoaded, setFetching, setEmbedCode]);

  useEffect(() => {
    loadVideo(url);
  }, [url]);

  if (!loaded && isFetching) {
    return <Loader />;
  } else if (loaded && embedCode) {
    return <div dangerouslySetInnerHTML={{ __html: embedCode }}></div>;
  } else {
    return (
      <div>
        <p>Fejl i video. Prøv igen senere.</p>
      </div>
    );
  }
};

export default VideoPlayer;

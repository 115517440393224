import React from 'react';

// Components
// global
import { Link } from 'react-router-dom';
// material-ui
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/Lock';

// css
import './ButtonPrimary.scss';

function buttonPrimary(props) {
  // console.log(props);

  let icon = null;
  if (props.icon === 'lock') {
    icon = <LockIcon className="btn-icon">lock</LockIcon>;
  }
  let link = null;
  if (props.link) {
    link = props.link;
  }
  let target = '_self';
  if (props.target) {
    target = props.target;
  }
  let fullWidth = false;
  if (props.fullWidth) {
    fullWidth = true;
  }

  switch (props.type) {
    case 'submit':
      return (
        <div className="dr-button-primary-wrapper">
          <Button
            classes={{ root: 'dr-button-primary' }}
            variant="contained"
            size="medium"
            color="primary"
            type="submit"
            fullWidth={fullWidth}
          >
            {icon}
            {props.children}
          </Button>
        </div>
      );
      break;
    case 'button':
      return (
        <div className="dr-button-primary-wrapper">
          <Button
            classes={{ root: 'dr-button-primary' }}
            onClick={props.action}
            component="div"
            variant="contained"
            size="medium"
            color="primary"
            fullWidth={fullWidth}
          >
            {icon}
            {props.children}
          </Button>
        </div>
      );
      break;
    case 'navlink':
      return (
        <div className="dr-button-primary-wrapper">
          <Button
            component={Link}
            to={link}
            classes={{ root: 'dr-button-primary' }}
            variant="contained"
            size="medium"
            color="primary"
            fullWidth={fullWidth}
          >
            {icon}
            {props.children}
          </Button>
        </div>
      );
      break;
    default:
      return (
        <div className="dr-button-primary-wrapper">
          <Button
            classes={{ root: 'dr-button-primary' }}
            target={target}
            component="a"
            href={link}
            variant="contained"
            size="medium"
            color="primary"
            fullWidth={fullWidth}
          >
            {icon}
            {props.children}
          </Button>
        </div>
      );
      break;
  }
}

export default buttonPrimary;

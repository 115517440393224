// building icons
import criticalDamageIcon from '@assets/icons/damage-icons/critical-damage-icon.svg';
import seriousDamageIcon from '@assets/icons/damage-icons/serious-damage-icon.svg';
import lessSeriousDamageIcon from '@assets/icons/damage-icons/less-serious-damage-icon.svg';
import potentialDamageIcon from '@assets/icons/damage-icons/potential-damage-icon.svg';
// electricity icons
import riskOfChockIcon from '@assets/icons/electricity-problems-icons/risk-of-chock-icon.svg';
import riskOfFireIcon from '@assets/icons/electricity-problems-icons/risk-of-fire-icon.svg';
import illegalInstallationsIcon from '@assets/icons/electricity-problems-icons/illegal-installations-icon.svg';
import examinedFurtherIcon from '@assets/icons/electricity-problems-icons/examined-further-icon.svg';

export const buildingReport = {
  type: 'building',
  mainHeading: 'Oversigt over skader',
  damageDescriptionBlock: {
    blockHeading: 'Det betyder vurderingerne',
    blocks: [
      {
        icon: criticalDamageIcon,
        title: 'Kritiske skader',
        description:
          'Skader ved bygningsdelen, som allerede har medført eller vil medføre, at bygningsdelens funktion svigter på kort sigt. Dette svigt kan medføre eller kan allerede have medført skader på andre bygningsdele.',
      },
      {
        icon: seriousDamageIcon,
        title: 'Alvorlige skader',
        description:
          'Skader ved bygningsdelen, som vil medføre, at bygningsdelens funktion svigter på længere sigt. Dette svigt kan medføre skader på andre bygningsdele.',
      },
      {
        icon: lessSeriousDamageIcon,
        title: 'Mindre alvorlige skader',
        description:
          'Skader, som ikke har nogen indflydelse på bygningsdelens eller bygningens funktion.',
      },
      {
        icon: potentialDamageIcon,
        title: 'Mulige skader',
        description:
          'Forhold ved bygningsdelen, som bør undersøges for at afklare, om det er en skade. Det kan være en kritisk eller en alvorlig skade. Derfor bør du afklare den mulige skades omfang og konsekvenser. Hvis du vælger at købe ejendommen, uden at forholdet er undersøgt nærmere, bærer du selv risikoen.',
      },
    ],
  },
  // row data for the defects table
  tableRows: [
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Placering',
      sortable: true,
    },
    {
      id: 'evaluation',
      numeric: false,
      disablePadding: false,
      label: 'Vurdering',
      sortable: true,
    },
    {
      id: 'damage',
      numeric: false,
      disablePadding: false,
      label: 'Skade',
    },
    {
      id: 'risk',
      numeric: false,
      disablePadding: false,
      label: 'Risiko',
    },
    {
      id: 'note',
      numeric: false,
      disablePadding: false,
      label: 'Bemærkning',
    },
  ],
  defects: [],
};

export const electricityReport = {
  type: 'electricity',
  mainHeading: 'Oversigt over fejl',
  damageDescriptionBlock: {
    blockHeading: 'Det betyder vurderingerne',
    blocks: [
      {
        icon: riskOfChockIcon,
        title: 'Risiko for stød',
        description:
          'Ulovlige elinstallationer, der indebærer risiko for elektrisk stød.',
      },
      {
        icon: riskOfFireIcon,
        title: 'Risiko for brand',
        description:
          'Ulovlige elinstallationer, der indebærer risiko for brand. ',
      },
      {
        icon: illegalInstallationsIcon,
        title: 'Ulovlige elinstallationer',
        description:
          'Elinstallationer, der ikke lever op til de standarder og lovkrav, der gjaldt, da installationen blev udført.',
      },
      {
        icon: examinedFurtherIcon,
        title: 'Undersøges nærmere',
        description:
          'Forhold ved elinstallationerne, der bør undersøges nærmere for at afklare, om der er en fejl. Det kan være en fejl med risiko for brand eller stød, og derfor bør du undersøge forholdet nærmere. Hvis du vælger at købe ejendommen, uden at forholdet er undersøgt nærmere, bærer du selv risikoen.',
      },
    ],
  },
  // row data for the defects table
  tableRows: [
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: 'Placering',
      sortable: true,
    },
    {
      id: 'evaluation',
      numeric: false,
      disablePadding: false,
      label: 'Vurdering',
      sortable: true,
    },
    {
      id: 'damage',
      numeric: false,
      disablePadding: false,
      label: 'Fejl',
    },
  ],
  // before doing table, look how read data will look like and read if filter options is clear.. - how to filter part is clear!!!
  defects: [],
};

// const apiReportDataLoaded: false,
// vocabularyInitialized: false,
// evaluationDetailsModalOpen: false,

// TEMPORARY DUMMY DATA!!
export const dummybuildings = [
  {
    buildingId: 1,
    buildingName: 'Beboelse',
  },
  {
    buildingId: 2,
    buildingName: 'Garage',
  },
  {
    buildingId: 3,
    buildingName: 'Værksted',
  },
];
export const dummylocations = [
  {
    locationId: 1,
    locationCategory: 'Ude',
    locationName: 'Tag',
  },
  {
    locationId: 2,
    locationCategory: 'Ude',
    locationName: 'Fundament',
  },
  {
    locationId: 3,
    locationCategory: 'Ude',
    locationName: 'Sokel',
  },
  {
    locationId: 4,
    locationCategory: 'Stueplan',
    locationName: 'Stue',
  },
  {
    locationId: 5,
    locationCategory: 'Stueplan',
    locationName: 'Køkken',
  },
  {
    locationId: 6,
    locationCategory: 'Stueplan',
    locationName: 'Badeværelse',
  },
  {
    locationId: 7,
    locationCategory: '1. sal',
    locationName: 'Gang',
  },
  {
    locationId: 8,
    locationCategory: '1. sal',
    locationName: 'Soveværelse',
  },
  {
    locationId: 9,
    locationCategory: '1. sal',
    locationName: 'Toilet',
  },
];

export const tabs = [
  {
    label: 'Skader i tilstandsrapporten',
  },
  {
    label: 'Fejl i elinstallationsrapporten',
  },
];

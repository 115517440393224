import React from 'react';

// Components
// material-ui
import Dialog from '@material-ui/core/Dialog';
// helpers
import { createMarkup } from './../../helpers';

// css
import './EvaluationDetailsModal.scss';

// assets
import closeIcon from '@assets/icons/modal-close-icon.svg';

function EvaluationDetailsModal(props) {
  let headerTitle = 'Læs mere om vurderingssystemet';
  let text = '';

  switch (props.reportType) {
    case 'building':
      text = `Hver konstateret skade under gennemgangen får en vurdering, der viser, hvor alvorlig skaden er. Skadens alvor vurderes ud fra, hvor store konsekvenser den kan have for bygningsdelen, og hvor akut det er at få den udbedret. <br />
      Den bygningssagkyndige kan knytte en bemærkning til skaden. En bemærkning er den bygningssagkyndiges skøn og vurdering af skadesårsag og konstruktioner. Vær opmærksom på, at ejerskifteforsikringen ikke dækker de registrerede skader.<br />
      <br />
      <div class="bold">Hvad betyder kort sigt?</div>
      Kort sigt er, når den bygningssagkyndige ud fra sin indsigt og erfaring skønner, at skaden udvikler sig negativt indenfor en periode på op til cirka 2 år. Det kan være meget individuelt, hvordan en skade udvikler sig. Det kan være væsentligt hurtigere end 2 år. Det er også muligt, at den negative udvikling allerede er begyndt.`;
      break;
    case 'electricity':
      text = `Hver konstateret fejl under gennemgangen får en vurdering. Alle fejl i elinstallationerne er ulovlige. Nogle er udelukkende ulovlige, mens andre både er ulovlige og indebærer risiko for brand og/eller stød. Alle fejl i oversigten kan have betydning for funktionsdygtigheden.<br />
Fejloversigten er dannet ud fra elinstallatørvirksomhedens gennemgang af installationer og installationsdele. De fejl i elinstallationerne, der indgår i fejloversigten, er ulovlige forhold. Nogle er udelukkende ulovlige, mens andre derudover udgør en risiko for brand eller stød.<br />
Alle fejl i oversigten kan have betydning for, om installationen fungerer korrekt. Men en funktionsfejl nævnes kun, hvis den samtidig udgør en ulovlig del af installationen.<br />
Du kan finde oplysninger om eventuelle funktionsfejl i den lovlige del af installationen i afsnittet ”Oplysninger om funktionsfejl” på side 3 i Elinstallationsrapporten. Vær opmærksom på, at ejerskifteforsikringen ikke dækker de anførte fejl.`;
      break;
  }

  return (
    <Dialog
      className="dr-evaluation-details-modal"
      open={props.open}
      onClose={props.modalClosed}
      maxWidth={false}
      scroll="body"
      classes={{ paper: 'paper-wrapper' }}
    >
      <div className="dr-modal-header">
        <div onClick={props.modalClosed} className="close-modal-icon-wrapper">
          <img src={'/' + closeIcon} className="close-modal-icon" />
        </div>
        {headerTitle}
      </div>

      <div className="dr-modal-body">
        <div className="text" dangerouslySetInnerHTML={createMarkup(text)} />
      </div>
    </Dialog>
  );
}

export default EvaluationDetailsModal;

import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PageHeader from '@/components/PageHeader';
import PageSection from '@/components/PageSection';
import CardsBlock from '@/components/CardsBlock';

import { replaceVocabularyWords } from '@/helpers';

import { getWords, getTransformedAttentionNotes } from '@/store/selectors';

import { pageDescription } from './constants';

const AttentionPage = ({ match }) => {
  const currentPageLink = useMemo(() => match.path.split('/').pop(), [match]);

  const words = useSelector(getWords);

  const attentionNotes = useSelector(getTransformedAttentionNotes);

  const transformedPageDescription = useMemo(
    () => replaceVocabularyWords(words, pageDescription),
    [words, pageDescription],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="dr-content-page">
      <div className="container">
        <PageHeader
          title="Opmærksomhedspunkter"
          description={transformedPageDescription}
        />
        <PageSection attentionNotes={attentionNotes} />
        <CardsBlock currentPage={currentPageLink} />
      </div>
    </div>
  );
};

export default AttentionPage;

/**
 * In card blocks we have few different block types:
 * - default
 * - reportBuildings
 * - buildingsInspectionSchemes
 * - electricityInstallations
 * - google-street-view
 */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
// custom
import SidebarReportsCardBlock from '../../components/SidebarReportsCardBlock/SidebarReportsCardBlock';
import CardsBlock from '../../components/CardsBlock';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import GivePermissionModal from '../../components/GivePermissionModal/GivePermissionModal';
// global
import { config } from '../../AppConfig';
// css
import './OverviewPage.scss';

import { getReportCreatorCompany } from '../../utils';

// selectors
import { getPropertyApiData, getPropertyOverview, getMunicipalityId, getBfeId } from '../../store/selectors';
import reportApi from '../../api/report';

const OverviewPage = (props) => {
  const propertyApiData = useSelector(getPropertyApiData);
  const property = useSelector(getPropertyOverview);
  const municipalityId = useSelector(getMunicipalityId);
  const bfeId = useSelector(getBfeId);

  const [permissionModalOpen, setPermissionModalOpen] = useState(false);
  const [publicViewPermission, setPublicViewPermission] = useState(false);

  const getDownloadLink = (type) => {
    if (propertyApiData.id && propertyApiData.reports[type]) {
      const propertyId = propertyApiData.id;
      const reportId = propertyApiData.reports[type].report_id;
      let url = `${config.api.apiEndpoint}/v2/reports/${reportId}/pdf`;
      if (bfeId.length === 0) {
        url = `${config.api.apiEndpoint}/municipalities/${municipalityId}/properties/${propertyId}/reports/${reportId}/pdf`;
      }
      return url;
    } else {
      return false;
    }
  };

  const reports = {
    buildingInspection: {
      type: 'building',
      inspectionName: 'Bygningseftersyn',
      conditionReportName: 'Tilstandsrapport',
      availableTo: '', // 1. december 2018
      downloadLink: getDownloadLink('house'),
      reportCreatorCompany: propertyApiData.reports.house
        ? getReportCreatorCompany(propertyApiData.reports.house.company)
        : null,
    },
    electricityInspection: {
      type: 'electricity',
      inspectionName: 'Eleftersyn',
      conditionReportName: 'Elinstallationsrapport',
      availableTo: '', // 1. maj 2018
      downloadLink: getDownloadLink('electrical'),
      reportCreatorCompany: propertyApiData.reports.electrical
        ? getReportCreatorCompany(propertyApiData.reports.electrical.company)
        : null,
    },
  };

  const closeGivePermissionModalHandler = () => {
    setPermissionModalOpen(false);
  };

  const openGivePermissionModalHandler = () => {
    setPermissionModalOpen(true);
  };

  const switchPublicViewPermissionHandler = (event) => {
    setPublicViewPermission(event.target.checked);

    reportApi.setPublicState(event.target.checked);
  };

  useEffect(() => {
    setPublicViewPermission(propertyApiData?.is_public);
  }, [propertyApiData?.is_public]);

  const mainReportDownloadLink = useMemo(() => {
    return (
      window.location.protocol +
      '//' +
      window.location.hostname +
      window.location.pathname
    );
  }, [propertyApiData]);

  return (
    <div className="dr-overview-page">
      <div className="container">
        <div className="dr-frontpage-header-block">
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="property-id">Ejendom {property.id}</div>
              <div className="property-address">{property.address1}</div>
              {property.address2 && (
                <div className="property-address">{property.address2}</div>
              )}
              <div className="property-zip-code">
                {property.city} {property.postcode}
              </div>
              <div className="property-info-wrapper">
                {propertyApiData.reports.house && (
                  <div className="property-area">
                    {property.area}m<sup>2</sup>
                  </div>
                )}
                <div className="property-description">
                  {property.description}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-5">
              <img
                src={property.imageURL}
                className="property-img img-responsive"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <CardsBlock currentPage="overview" />
          </div>

          <div className="col-lg-4">
            {propertyApiData.boligejer_token && (
              <div className="btn-provide-power">
                <ButtonPrimary
                  action={openGivePermissionModalHandler}
                  type="button"
                  icon="lock"
                  fullWidth
                >
                  Del rapporterne
                </ButtonPrimary>
              </div>
            )}
            <SidebarReportsCardBlock reports={reports} />
          </div>
        </div>

        {propertyApiData.boligejer_token && (
          <GivePermissionModal
            publicViewPermission={publicViewPermission} // did user give permission to see report
            switchPermissionHandler={switchPublicViewPermissionHandler} // toggle Yes/No publicViewPermission
            open={permissionModalOpen}
            modalClosed={closeGivePermissionModalHandler}
            downloadReportLink={mainReportDownloadLink}
          />
        )}
      </div>
    </div>
  );
};

export default OverviewPage;

import React from 'react';

// Components
// material-ui
import Button from '@material-ui/core/Button';

// css
import './ButtonTransparentPrimary.scss';

function buttonTransparentPrimary(props) {
  let target = '_self';
  if (props.target) {
    target = props.target;
  }
  let button = null;

  switch (props.type) {
    case 'link':
      button = (
        <Button
          variant="outlined"
          target={target}
          component="a"
          href={props.link || ''}
          onClick={props.action}
          color="primary"
          classes={{ root: 'dr-button-transparent-primary' }}
        >
          {props.children}
        </Button>
      );
      break;
    case 'button':
      button = (
        <Button
          variant="outlined"
          component="div"
          color="primary"
          classes={{ root: 'dr-button-transparent-primary' }}
          onClick={props.action}
        >
          {props.children}
        </Button>
      );
      break;
    default:
      return null;
      break;
  }
  return <div className="dr-button-transparent-primary-wrapper">{button}</div>;
}

export default buttonTransparentPrimary;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
// global
import axios from 'axios';
import { config } from '@/AppConfig';
// custom
import PageHeader from '@/components/PageHeader';
import PageSubHeader from '@/components/PageSubHeader';
import CardsBlock from '@/components/CardsBlock';
import Tabs from '@/components/Tabs/Tabs';
import DamageDescription from '@/components/DamageDescription/DamageDescription';
import FilterTable from '@/components/FilterTable/FilterTable';
import Aux from '@/hoc/Auxiliary/Auxiliary';
import Loader from '@/components/Loader/Loader';

// helpers
import { replaceVocabularyWords } from '@/helpers';

// css
import './ReportsCommentsPage.scss';

import { getBasePath, getPropertyApiData, getWords } from '@/store/selectors';
import { buildingReport, electricityReport, tabs } from './constants';

import { getDefectsFromJSON } from '@/utils';

const ReportsCommentsPage = ({ location }) => {
  const basePath = useSelector(getBasePath);
  const property = useSelector(getPropertyApiData);
  const words = useSelector(getWords);

  const currentPageLink = location.pathname.split('/').pop();

  const [activeTab, setActiveTab] = useState(0);
  const [isVocabInit, setIsVocabInit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { buildingDefects = [], electricalDefects = [] } = useMemo(() => {
    const buildingDefects = getDefectsFromJSON(property, 'house');
    const electricalDefects = getDefectsFromJSON(property, 'electrical');

    return {
      buildingDefects: buildingDefects.map((defect = {}) => {
        const mutable = { ...defect };
        if (mutable.damage !== null && mutable.damage !== '') {
          mutable.damage = replaceVocabularyWords(words, mutable.damage);
        }
        if (mutable.risk !== null && mutable.risk !== '') {
          mutable.risk = replaceVocabularyWords(words, mutable.risk);
        }
        if (mutable.note !== null && mutable.note !== '') {
          mutable.note = replaceVocabularyWords(words, mutable.note);
        }
        return mutable;
      }),
      electricalDefects: electricalDefects.map((defect = {}) => {
        const mutable = { ...defect };
        if (mutable.damage !== null && mutable.damage !== '') {
          mutable.damage = replaceVocabularyWords(words, mutable.damage);
        }
        if (mutable.risk !== null && mutable.risk !== '') {
          mutable.risk = replaceVocabularyWords(words, mutable.risk);
        }
        if (mutable.note !== null && mutable.note !== '') {
          mutable.note = replaceVocabularyWords(words, mutable.note);
        }
        return mutable;
      }),
    };
  }, [words, property]);

  const tabClickHandler = (event, value) => {
    setActiveTab(value);
  };

  const showEvaluationDetailsModalHandler = () => {
    setModalOpen(true);
  };

  const closeEvaluationDetailsModalHandler = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    // scroll window up, when navigation through different links
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="dr-reports-comments-page">
      <div className="container">
        <PageHeader title={ReportsCommentsPage.options.pageTitle} />
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          tabClicked={tabClickHandler}
          variant="fullWidth"
        />
        <Aux>
          {activeTab === 0 ? (
            <div className="tab-content">
              <PageSubHeader uppercase>
                {buildingReport.mainHeading}
              </PageSubHeader>

              <DamageDescription
                reportType={buildingReport.type}
                data={buildingReport.damageDescriptionBlock}
                evaluationDetailsModalOpen={modalOpen}
                closeEvaluationDetailsModalHandler={
                  closeEvaluationDetailsModalHandler
                }
                showEvaluationDetailsModalHandler={
                  showEvaluationDetailsModalHandler
                }
              />

              <FilterTable
                reportType={buildingReport.type}
                tableRows={buildingReport.tableRows}
                defects={buildingDefects}
              />
            </div>
          ) : null}

          {activeTab === 1 ? (
            <div className="tab-content">
              <PageSubHeader uppercase>
                {electricityReport.mainHeading}
              </PageSubHeader>

              <DamageDescription
                reportType={electricityReport.type}
                data={electricityReport.damageDescriptionBlock}
                evaluationDetailsModalOpen={modalOpen}
                closeEvaluationDetailsModalHandler={
                  closeEvaluationDetailsModalHandler
                }
                showEvaluationDetailsModalHandler={
                  showEvaluationDetailsModalHandler
                }
              />

              <FilterTable
                reportType={electricityReport.type}
                tableRows={electricityReport.tableRows}
                defects={electricalDefects}
              />
            </div>
          ) : null}
        </Aux>
        <CardsBlock currentPage={currentPageLink} />
      </div>
    </div>
  );
};

ReportsCommentsPage.options = {
  pageTitle: 'Fejl og skader i rapporterne',
};

export default ReportsCommentsPage;

import React from 'react';

// css
import './Footer.scss';

const Footer = () => {
  const info = {
    name: 'Sikkerhedsstyrelsen',
    address: 'Esbjerg Brygge 30',
    zipCode: '6700 Esbjerg',
    phone: '33 73 20 00',
    email: 'sik@sik.dk',
    openingHours: 'Mandag - torsdag: 8:00 - 15:00 og fredag: 8:00 - 14:00',
  };

  return (
    <footer className="dr-footer">
      <div className="container">
        <div className="main-organisation-info">
          <ul className="list clearfix">
            <li className="list-item">{info.name}</li>
            <li className="list-item">{info.address}</li>
            <li className="list-item">{info.zipCode}</li>
            <li className="list-item">Tlf. {info.phone}</li>
            <li className="list-item">
              Email: <a href={`mailto: ${info.email}`}>{info.email}</a>
            </li>
          </ul>
        </div>
        <div className="opening-hours">Åbningstider: {info.openingHours}</div>
      </div>
    </footer>
  );
};

export default Footer;

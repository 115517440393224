import React from 'react';

// Components
// material-ui
import Typography from '@material-ui/core/Typography';
// custom
import Aux from './../../hoc/Auxiliary/Auxiliary';
import ButtonTransparentPrimary from './../ButtonTransparentPrimary/ButtonTransparentPrimary';

// css
import './CardTransparent.scss';

// assets
import buildingInspectionIcon from '@assets/icons/building-inspection-icon.svg';
import electricityInspectionIcon from '@assets/icons/electricity-inspection-icon.svg';
import { store } from '@/store';

const CardSwitch = ({ cardType, report }) => {
  switch (cardType) {
    case 'downloadReport':
      return (
        <Aux>
          <div className="subheading">{report.conditionReportName}</div>
          {report.availableTo !== '' && report.availableTo !== null ? (
            <div className="available-to">Gyldig til {report.availableTo}</div>
          ) : (
            <div>&nbsp;</div>
          )}
          <ButtonTransparentPrimary
            type="button"
            action={() => {
              const boligejerToken = store.getState()?.data?.property?.data?.boligejer_token
              let headers = { 'Content-Type': 'application/pdf' };
              if (boligejerToken) {
                headers['boligejer-token'] = boligejerToken;
              }

              fetch(report.downloadLink, {
                headers: headers,
              })
                // Convert response to blob like response.json()
                .then((response) => response.blob())
                .then((blob) => {
                  // Create blob link to download
                  const url = window.URL.createObjectURL(
                    new Blob([blob]),
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = `${report.conditionReportName}.pdf`;

                  document.body.appendChild(link);
                  link.click();
                  link.parentNode.removeChild(link);
                });
              }
            }
          >
            Download
          </ButtonTransparentPrimary>
        </Aux>
      );
    case 'reportCompanyInfo':
      return (
        <Aux>
          <Aux>
            <div className="report-company-name">
              {report.reportCreatorCompany
                ? report.reportCreatorCompany.companyName
                : '-'}
            </div>
            <div className="report-company-information">
              {report.reportCreatorCompany
                ? report.reportCreatorCompany.companyInfo
                    .split('\n')
                    .map((x, i) => <p key={i}>{x}</p>)
                : '-'}
            </div>
          </Aux>
        </Aux>
      );
    default:
      return null;
  }
};

const CardIcon = ({ type }) => {
  switch (type) {
    case 'building':
      return <img src={`/${buildingInspectionIcon}`} className="card-icon" />;

    case 'electricity':
      return (
        <img src={`/${electricityInspectionIcon}`} className="card-icon" />
      );
    default:
      null;
  }
};

function CardTransparent({ report, cardType }) {
  return (
    <div className="dr-card-transparent-item">
      <CardIcon type={report.type} />
      <Typography gutterBottom variant="h5" component="h2" className="heading">
        {report.inspectionName}
      </Typography>
      <CardSwitch cardType={cardType} report={report} />
    </div>
  );
}

export default CardTransparent;

import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PageHeader from '@/components/PageHeader';
import PageSection from '@/components/PageSection';
import CardsBlock from '@/components/CardsBlock';

import {
  replaceVocabularyWords,
  setClickVocabularyWordHandler,
} from '@/helpers';

import {
  getPropertyApiData,
  getWords,
  getCurrentVocabularyWord,
} from '@/store/selectors';

import { getSelectedWord } from '@/store/data/vocab/creators';

const AboutHousePage = ({ match }) => {
  const dispatch = useDispatch();

  const property = useSelector(getPropertyApiData);
  const words = useSelector(getWords);
  const currentWord = useSelector(getCurrentVocabularyWord);

  const currentPageLink = useMemo(() => match.path.split('/').pop(), [match]);
  const description = useMemo(
    () => property.type.description.replace(/(?:\r\n|\r|\n)/g, '<br />'),
    [property],
  );
  const transformedDescription = useMemo(
    () => replaceVocabularyWords(words, description),
    [words, description],
  );

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <div className="dr-content-page">
      <div className="container">
        <PageHeader title={property.type.type} />

        <PageSection text={transformedDescription} />

        <CardsBlock currentPage={currentPageLink} />
      </div>
    </div>
  );
};

export default AboutHousePage;

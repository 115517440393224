import React from 'react';

// custom
import VideoPlayer from '../VideoPlayer';
import Gallery from '../Gallery/Gallery';
import TableWidget from '../TableWidget/TableWidget';
import AttentionNotes from '../AttentionNotes/AttentionNotes';
import ContentWithVocabulary from '@/components/FilterTable/ContentWithVocabulary';
// helpers
import { createMarkup } from './../../helpers';

// css
import './PageSection.scss';

const PageSection = (props) => {
  return (
    <section>
      {props.title && <h3 className="section-title">{props.title}</h3>}
      {props.text && (
        <ContentWithVocabulary className="section-text" text={props.text} />
      )}
      {props.video && (
        <div className="section-item">
          <VideoPlayer url={props.video} />
        </div>
      )}
      {props.gallery && (
        <div className="section-item">
          <Gallery items={props.gallery} />
        </div>
      )}
      {props.table && <TableWidget table={props.table} />}
      {props.attentionNotes && (
        <AttentionNotes attentionNotes={props.attentionNotes} />
      )}
    </section>
  );
};

export default PageSection;

import React, { useMemo, useCallback, useState, useEffect } from 'react';

// custom
import ButtonPrimary from './../ButtonPrimary/ButtonPrimary';

import BuildingFilter from './BuildingFilter';
import FilterActions from './FilterActions';
import FilterTableResults from './FilterTableResults';
import { config } from '../../AppConfig';

// css
import './FilterTable.scss';

const FilterTable = ({
  reportType,
  getSelectedItemData = [],
  tableRows = [],
  defects: propDefects = [],
}) => {
  const {
    // Variables
    filters,
    defects,
    pdfData = {},
    buildingFilterArray,
    locationFilterArray,
    evaluations,

    // Functions
    handleChangeFilters,
    buildBuildingDefects,
  } = useComponent({
    reportType,
    getSelectedItemData,
    tableRows,
    defects: propDefects,
  });
  return (
    <div className="dr-filter-table-block">
      <div className="filter-selects-block">
        <BuildingFilter
          buildings={buildingFilterArray}
          selectedBuildingIndex={filters.selectedBuildingIndex}
          onClicked={buildBuildingDefects}
        />
        <FilterActions
          initDefects={propDefects}
          filters={filters}
          locationFilterArray={locationFilterArray}
          evaluations={evaluations}
          changeFilters={handleChangeFilters}
        />
      </div>

      <FilterTableResults
        reportType={reportType}
        tableRows={tableRows}
        defects={defects}
        getSelectedItemData={getSelectedItemData}
      />
      <div className="row justify-content-center">
        <div className="col-sm-4">
          <form
            action={config.api.downloadDefectsTable}
            method="post"
            target="_blank"
          >
            <input
              type="hidden"
              name="buildingName"
              value={pdfData.buildingName}
            />
            <input type="hidden" name="reportType" value={reportType} />
            <input type="hidden" name="defects" value={pdfData.defects} />

            <ButtonPrimary type="submit" fullWidth>
              Download tabel som pdf
            </ButtonPrimary>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FilterTable);

const useComponent = ({ defects: propDefects = [] }) => {
  const [buildingDefects, setBuildingDefects] = useState([]);
  const [filteredApplied, setFilteredApplied] = useState(false);
  const [filteredDefects, setFilteredDefects] = useState([]);
  const [filters, setFilters] = useState({
    selectedBuildingIndex: 0,
    selectedLocations: [],
    locationsOpened: false,
    selectedEvaluations: [],
    evaluationsOpened: false,
  });

  /**
   * Easier change filter function.
   *
   * @param {object} newFilters Set the new filter keys.
   *
   * @return {void}
   */
  const handleChangeFilters = useCallback(
    (newFilters = {}) => {
      return setFilters((prev = {}) => ({
        ...prev,
        ...newFilters,
      }));
    },
    [setFilters],
  );

  /**
   * Memo defects either the filteredDefects or the buildingDefects.
   *
   * @depends {filteredApplied, filteredDefects, buildingDefects}
   *
   * @returns {defects}
   */
  const defects = useMemo(() => {
    if (filteredApplied) {
      return filteredDefects;
    }

    return buildingDefects;
  }, [filteredApplied, filteredDefects, buildingDefects]);

  /**
   * Memo filtered variables from the propDefects values.
   *
   * @depends propDefects
   *
   * @returns {buildingFilterArray, locationFilterArray, evaluations}
   */
  const {
    buildingFilterArray = [],
    locationFilterArray = [],
    evaluations = [],
  } = useMemo(() => {
    const evaluations = [];
    const locationFilterArray = [];
    const buildingsArrayIndexes = [];
    const buildingsArray = [];

    (propDefects || []).forEach((defect) => {
      // Buildings setup.
      if (
        defect.building &&
        buildingsArrayIndexes.indexOf(defect.building.buildingIndex) === -1
      ) {
        buildingsArrayIndexes.push(defect.building.buildingIndex);
        buildingsArray.push(defect.building);
      }

      if (defect.evaluation) {
        // Evaluation setup.
        let evaluationExists = false;
        evaluations.forEach(function(el) {
          if (defect.evaluation.defectLevel === el.defectLevel) {
            evaluationExists = true;
          }
        });
        if (evaluationExists === false) {
          evaluations.push(defect.evaluation);
        }
      }

      // Location setup.
      let currentCategoryObj = null;
      let categoryExists = false;

      locationFilterArray.forEach(function(el) {
        if (defect.location.locationCategory === el.locationCategory) {
          categoryExists = true;
          currentCategoryObj = el;
        }
      });

      if (categoryExists === false) {
        currentCategoryObj = {
          locationCategory: defect.location.locationCategory,
          locations: [],
        };
        locationFilterArray.push(currentCategoryObj);
      }

      let locationExists = false;

      currentCategoryObj.locations.forEach(function(el) {
        if (defect.location.locationId === el.locationId) {
          locationExists = true;
        }
      });

      if (locationExists === false) {
        currentCategoryObj.locations.push({
          locationId: defect.location.locationId,
          locationName: defect.location.locationName,
        });
      }
    });

    return {
      buildingFilterArray: buildingsArray,
      locationFilterArray: locationFilterArray.sort((a, b) => {
        if (b.locationCategory < a.locationCategory) {
          return 1;
        } else if (b.locationCategory > a.locationCategory) {
          return -1;
        } else {
          return 0;
        }
      }),
      evaluations: evaluations.sort((a, b) => {
        if (b.sortingOrder < a.sortingOrder) {
          return 1;
        } else if (b.sortingOrder > a.sortingOrder) {
          return -1;
        } else {
          return 0;
        }
      }),
    };
  }, [propDefects]);

  /**
   * Update the building defects.
   *
   * @param {int} buildingIndex The index of the selected building.
   *
   * @depends {filters.selectedBuildingIndex, defects, locationFilterArray, handleChangeFilters, setBuildingDefects, setFilteredDefects, setFilteredApplied}
   * @return {void}
   */
  const buildBuildingDefects = useCallback(
    (buildingIndex = null) => {
      let newSelectedBuildingId = null;

      // in the beginning we just take the first building in the array to be active (selected)..
      // if we cal setBuildingDefects when clicking on the building button and send buildingId with, we use this buildingId..
      if (buildingIndex !== null) {
        // check if clicked building is the same as current selectedBuilding.. If yes, no reason to change anything
        if (filters.selectedBuildingIndex === buildingIndex) {
          return;
        } else {
          newSelectedBuildingId = buildingIndex;
        }
      } else {
        // set curent building to the first building in the array..
        newSelectedBuildingId = (buildingFilterArray[0] || {}).buildingIndex;
      }

      let buildingDefects = [];
      propDefects.map((defect) => {
        if (defect.building.buildingIndex === newSelectedBuildingId) {
          // add defect to buildingDefects if his buildingId is the same as selectedBuilding
          buildingDefects.push(defect);
        }
      });

      setBuildingDefects(buildingDefects);
      setFilteredDefects([]);
      setFilteredApplied(false);

      handleChangeFilters({
        selectedBuildingIndex: newSelectedBuildingId,
        selectedLocations: [],
        selectedEvaluations: [],
      });
    },
    [
      filters.selectedBuildingIndex,
      defects,
      locationFilterArray,
      handleChangeFilters,
      setBuildingDefects,
      setFilteredDefects,
      setFilteredApplied,
    ],
  );

  useEffect(() => {
    let filteredDefects = [];

    if (
      filters.selectedLocations.length > 0 &&
      filters.selectedEvaluations.length > 0
    ) {
      buildingDefects.map((defect) => {
        if (
          filters.selectedLocations.indexOf(defect.location.locationId) > -1 ||
          filters.selectedLocations.indexOf(defect.location.locationCategory) >
            -1
        ) {
          if (
            defect.evaluation &&
            filters.selectedEvaluations.indexOf(defect.evaluation.defectLevel) >
              -1
          ) {
            filteredDefects.push(defect);
          }
        }
      });
    } else {
      if (filters.selectedLocations.length > 0) {
        buildingDefects.map((defect) => {
          if (
            filters.selectedLocations.indexOf(defect.location.locationId) >
              -1 ||
            filters.selectedLocations.indexOf(
              defect.location.locationCategory,
            ) > -1
          ) {
            filteredDefects.push(defect);
          }
        });
      }

      if (filters.selectedEvaluations.length > 0) {
        buildingDefects.map((defect) => {
          if (
            defect.evaluation &&
            filters.selectedEvaluations.indexOf(defect.evaluation.defectLevel) >
              -1
          ) {
            filteredDefects.push(defect);
          }
        });
      }
    }

    // if one of filters applied, show empty table, if no defects are in filteredDefects
    let filteredApplied = false;
    if (
      filters.selectedLocations.length > 0 ||
      filters.selectedEvaluations.length > 0
    ) {
      filteredApplied = true;
    }

    setFilteredApplied(filteredApplied);
    setFilteredDefects(filteredDefects);
  }, [
    filters.selectedLocations,
    filters.selectedEvaluations,
    buildingDefects,
    setFilteredApplied,
    setFilteredDefects,
  ]);

  /**
   * Remove all <span> and other html tags from our defects so html markup will not appear in pdf document.
   *
   * @param {array} defects
   *
   * @returns JSON string
   */
  const getCleanSelectedDefects = useCallback((defects = []) => {
    let defectsUpdated = [];

    defects.map((defect) => {
      let defectUpdated = Object.assign({}, defect);

      if (defectUpdated.damage !== null && defectUpdated.damage !== '') {
        defectUpdated.damage = defectUpdated.damage.replace(
          /<\/?[^>]+(>|$)/g,
          '',
        );
      }
      if (defectUpdated.risk !== null && defectUpdated.risk !== '') {
        defectUpdated.risk = defectUpdated.risk.replace(/<\/?[^>]+(>|$)/g, '');
      }
      if (defectUpdated.note !== null && defectUpdated.note !== '') {
        defectUpdated.note = defectUpdated.note.replace(/<\/?[^>]+(>|$)/g, '');
      }

      defectsUpdated.push(defectUpdated);
    });

    return JSON.stringify(defectsUpdated);
  }, []);

  /**
   * Memo pdf data.
   *
   * @depends {buildingFilterArray, filters.selectedBuildingIndex, defects, getCleanSelectedDefects}
   *
   * @return {object}
   */
  const pdfData = useMemo(() => {
    return {
      buildingName: (buildingFilterArray[filters.selectedBuildingIndex] || {})
        .buildingName,
      defects: getCleanSelectedDefects(defects),
    };
  }, [
    buildingFilterArray,
    filters.selectedBuildingIndex,
    defects,
    getCleanSelectedDefects,
  ]);

  useEffect(() => {
    buildBuildingDefects(filters.selectedBuilding);
  }, [propDefects]);

  return useMemo(() => {
    return {
      // Variables.
      filters,
      defects,
      pdfData,
      buildingDefects,
      filteredApplied,
      filteredDefects,
      buildingFilterArray,
      locationFilterArray,
      evaluations,

      // Functions.
      handleChangeFilters,
      buildBuildingDefects,
    };
  }, [
    filters,
    defects,
    pdfData,
    buildingDefects,
    filteredApplied,
    filteredDefects,
    locationFilterArray,
    buildingFilterArray,
    evaluations,

    handleChangeFilters,
    buildBuildingDefects,
  ]);
};

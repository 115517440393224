import React, { Component } from 'react';

// Components
// custom
import LoginModal from './../../components/LoginModal/LoginModal';
import ButtonPrimary from './../../components/ButtonPrimary/ButtonPrimary';

// css
import './LoginPage.scss';

class LoginPage extends Component {
  state = {
    messageAgreed: false, // does user accepted the message in modal
    messageModalOpen: true, // does login modal opened?
    errorModalMessageShow: false, // show error in modal?
  };

  // log = () => {
  //   console.log('log LoginPage');
  //   console.log(this.state);
  // };

  /**
   * Checkbox click in the login modal window
   * @param event
   */
  changeCheckboxHandler = (event) => {
    this.setState({
      messageAgreed: event.target.checked,
    });
  };

  /**
   * Checkbox label click in the login modal window
   */
  changeCheckboxLabelHandler = () => {
    this.setState({
      messageAgreed: !this.state.messageAgreed,
    });
  };

  /**
   * Close login modal functionality
   * We can only close it, if user accept the checkbox..
   */
  closeLoginModalHandler = () => {
    if (this.state.messageAgreed === true) {
      this.setState({
        messageModalOpen: false,
      });
    } else {
      if (this.state.errorModalMessageShow === false) {
        this.setState({
          errorModalMessageShow: true,
        });
      }
    }
  };

  render() {
    return (
      <div className="dr-login-page">
        <div className="container">
          <div className="title">Login i mit huseftersyn</div>

          <div className="row justify-content-center">
            <div className="col-sm-4">
              <ButtonPrimary link={'/'}>Login</ButtonPrimary>
            </div>
          </div>

          <LoginModal
            open={this.state.messageModalOpen}
            messageAgreed={this.state.messageAgreed}
            showErrorMessage={this.state.errorModalMessageShow}
            changeCheckbox={this.changeCheckboxHandler}
            changeLabelCheckbox={this.changeCheckboxLabelHandler}
            modalClosed={this.closeLoginModalHandler}
          />
        </div>
      </div>
    );
  }
}

export default LoginPage;

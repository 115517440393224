import { combineReducers } from 'redux';

import {
  SET_MUNICIPALITY_ID,
  SET_CADASTRAL_ID,
  SET_BFE_ID,
  SET_BASE_PATH,
  PROPERTY_ERROR,
  PROPERTY_REQUEST,
  PROPERTY_SUCCESS,
} from './actions';

const cadastralId = (state = '', action) => {
  switch (action.type) {
    case SET_CADASTRAL_ID:
      return action.value;
    default:
      return state;
  }
};

const municipalityId = (state = '', action) => {
  switch (action.type) {
    case SET_MUNICIPALITY_ID:
      return parseInt(action.value);
    default:
      return state;
  }
};

const bfeId = (state = '', action) => {
  switch (action.type) {
    case SET_BFE_ID:
      return action.value;
    default:
      return state;
  }
};

const noProperty = (state = true, action) => {
  switch (action.type) {
    case PROPERTY_SUCCESS:
      return false;
    case PROPERTY_ERROR:
      return true;
    default:
      return state;
  }
};

const basePath = (state = '', action) => {
  switch (action.type) {
    case SET_BASE_PATH:
      return action.value;

    default:
      return state;
  }
};

const data = (state = {}, action) => {
  switch (action.type) {
    case PROPERTY_SUCCESS:
      return action.data;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case PROPERTY_REQUEST:
      return true;
    case PROPERTY_SUCCESS:
    case PROPERTY_ERROR:
      return false;
    default:
      return state;
  }
};

const loaded = (state = false, action) => {
  switch (action.type) {
    case PROPERTY_SUCCESS:
    case PROPERTY_ERROR:
      return true;
    case PROPERTY_REQUEST:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case PROPERTY_ERROR:
      return action.message;
    default:
      return state;
  }
};

export default combineReducers({
  cadastralId,
  municipalityId,
  bfeId,
  noProperty,
  basePath,
  data,
  isFetching,
  error,
  loaded,
});

import axios from 'axios';

axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
  Accept: 'application/json',
};

axios.defaults.crossDomain = true;
axios.defaults.preflightContinue = true;
axios.defaults.withCredentials = false;

export default axios;

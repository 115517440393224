import axios from '@/api';
import { config } from '@/AppConfig';

import { getCurrentPath } from '@/store/selectors';

import {
  PAGE_REQUEST,
  PAGE_SUCCESS,
  SET_CURRENT_PATH,
} from './actions';

export const setCurrentPath = (path) => ({
  type: SET_CURRENT_PATH,
  value: path,
});

export const fetchPageData = () => (dispatch, getState) => {
  const state = getState();
  const path = getCurrentPath(state);

  const url = config.api.page + path;

  dispatch({ type: PAGE_REQUEST });

  axios.get(url).then((res) => {
    dispatch({ type: PAGE_SUCCESS, data: res.data });
  });
};

import React, { useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';

// custom
import ButtonPrimary from '@/components/ButtonPrimary/ButtonPrimary';

// css
import './GivePermissionModal.scss';

// assets
import closeIcon from '@assets/icons/modal-close-icon.svg';

import { copyToClipboard } from '@/utils';

const GivePermissionModal = ({
  publicViewPermission,
  switchPermissionHandler,
  open,
  modalClosed,
  downloadReportLink,
}) => {
  const copy = useCallback(() => {
    const element = document.getElementById('download-report-link');
    copyToClipboard(element, downloadReportLink);
  }, [downloadReportLink]);

  const canCopy = document.queryCommandSupported('copy');

  return (
    <Dialog
      className="dr-give-permission-modal"
      open={open}
      onClose={modalClosed}
      scroll="body"
      classes={{ paper: 'paper-wrapper' }}
    >
      <div className="give-permission-modal-header">
        <div onClick={modalClosed} className="close-modal-icon-wrapper">
          <img src={'/' + closeIcon} className="close-modal-icon" />
        </div>
        DEL RAPPORTERNE MED ANDRE
      </div>

      <div className="give-permission-modal-body flex flex-column ">
        <div className="text">
          {publicViewPermission ? (
            <div>
              Rapporterne er nu åbne. Alle, du sender linket til, kan se
              rapporterne.
              <br />
              <br />
              Hvis du ændrer knappen fra ”Åben” til ”Lukket”, er det igen kun
              dig, der kan se rapporterne.
              <br />
              <br />
              Rapporternes link er: {downloadReportLink}
              <br />
              <br />
            </div>
          ) : (
            <div>
              Vil du dele et link til rapporterne med andre? Alle, som har
              linket, kan se rapporterne. <br />
              <br />
              Ændr knappen fra ”Lukket” til ”Åben”. Så giver du fuldmagt til, at
              andre kan se rapporterne, og du kan danne et link til rapporterne.
              Det kræver NEM-ID.
            </div>
          )}
        </div>

        <div className="f1" />

        <div className="row justify-content-between">
          <div className="col-auto">
            <div className="toggle-wrapper">
              <Switch
                checked={publicViewPermission}
                onChange={switchPermissionHandler}
                color="primary"
              />
              <div className="toggle-status">
                {publicViewPermission ? (
                  <span className="opened">Åben</span>
                ) : (
                  <span>Lukket</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-auto">
            {publicViewPermission && (
              <div className="btn-copy-link">
                <ButtonPrimary type="button" action={copy}>
                  Kopier link
                </ButtonPrimary>
                <input id="download-report-link" className="hidden-field" />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GivePermissionModal;

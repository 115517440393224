import React, { useMemo } from 'react';

import ButtonFilterPrimary from './../../components/ButtonFilterPrimary/ButtonFilterPrimary';

const BuildingFilter = ({
  buildings = [],
  selectedBuildingIndex = 0,
  onClicked,
}) => {
  if (buildings.length === 0) {
    return null;
  }

  return (
    <div className="building-filter-wrapper">
      {buildings.map((building, index) => {
        return (
          <ButtonFilterPrimary
            isActive={building.buildingIndex === selectedBuildingIndex}
            key={index}
            clicked={() => onClicked(building.buildingIndex)}
          >
            {building.buildingName}
          </ButtonFilterPrimary>
        );
      })}
    </div>
  );
};

export default React.memo(BuildingFilter);

import React from 'react';
import { useHistory } from 'react-router-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

export const theme = {
  palette: {
    primary: { main: '#f7b700' },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    useNextVariants: true,
    // Use custom font
    fontFamily: ['Rawline Regular', 'Helvetica', 'Roboto'].join(','),
  },
  overrides: {},
};


function ThemeProviders(props) {
  const history = useHistory();

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      {props.children}
    </MuiThemeProvider>
  );
};

export default ThemeProviders;

import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
// global
import Downshift from 'downshift';
import axios from 'axios';
import { config } from '../../AppConfig';
// material-ui
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

// css
import './Vocabulary.scss';

// assets
import autocompleteMagnifyIcon from '@assets/icons/autocomplete-magnify-icon.svg';

import { getSelectedWord } from '@/store/data/vocab/creators';

import { getWords } from '@/store/selectors';

const Vocabulary = (props) => {
  const dispatch = useDispatch();
  const words = useSelector(getWords);

  const [listWords, setListWords] = useState([]);
  const [autoCompleteActive, setAutoCompleteActive] = useState(false);

  const getWord = (id) => dispatch(getSelectedWord(id));

  useEffect(() => {
    axios
      .get(config.api.promoted)
      .then((response) => {
        setListWords(response.data);
      })
      .catch((error) => {
        console.log(config.api.promoted, error);
      });
  }, []);

  // get list of words that match the current input, limited to 10 results
  const getSuggestionsHandler = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return words
      .filter(
        (word) =>
          word.label.substr(0, inputLength).toLowerCase() === inputValue,
      )
      .slice(0, 10);
  };

  /**
   * Called, when user selects a value from autocomplete
   * @param selectedItem
   */
  const handleAutoComplete = (word) => {
    getWord(word.id);
  };

  const handleWordClick = (id) => () => {
    getWord(id);
  };

  // controls show/hide of the magnifier icon
  // we do not want to show a icon, when user have clicked on autocomplete, as you can not search by clicking on icon
  const autocompleteFocusHandler = () => {
    setAutoCompleteActive(true);
  };

  // controls show/hide of the magnifier icon
  const autocompleteBlurHandler = () => {
    setAutoCompleteActive(false);
  };

  const vocabularyHeading = 'Ordbogen - A til Å';
  const vocabularyDescription =
    'Du kan finde forklaringer på fagudtryk i ordbogen. Brug søgefunktionen.';

  return (
    <div className="dr-vocabulary">
      <div className="vocabulary-header">
        <h2 className="heading">{vocabularyHeading}</h2>
        <div className="description">{vocabularyDescription}</div>
      </div>
      <div className="autocomplete-block">
        <Downshift
          id="downshift-simple"
          itemToString={(item) => (item ? item.label : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            inputValue,
            isOpen,
            selectedItem,
            clearSelection,
            highlightedIndex,
          }) => {
            return (
              <div className="downshift-main-wrapper">
                <div className="autocomplete-input-wrapper">
                  {!autoCompleteActive ? (
                    <img
                      src={`/${autocompleteMagnifyIcon}`}
                      className="autocomplete-icon"
                    />
                  ) : null}
                  <TextField
                    InputProps={{
                      classes: {
                        root: 'autocomplete-input-root',
                        input: 'autocomplete-input-field',
                      },
                    }}
                    {...getInputProps({
                      placeholder: 'Søg i ordbogen',
                      onFocus: autocompleteFocusHandler,
                      onBlur: autocompleteBlurHandler,
                    })}
                  />
                </div>
                <div {...getMenuProps()}>
                  {isOpen ? (
                    <Paper className="suggestions-wrapper" square>
                      {getSuggestionsHandler(inputValue).map(
                        (suggestion, index) => {
                          return renderSuggestionHandler({
                            clearSelection,
                            suggestion,
                            index,
                            highlightedIndex,
                            handleClick: handleAutoComplete,
                            itemProps: getItemProps({
                              item: suggestion,
                              classes: { root: 'suggestion-item' },
                            }),
                          });
                        },
                      )}
                    </Paper>
                  ) : null}
                </div>
              </div>
            );
          }}
        </Downshift>
      </div>

      <div className="word-list-wrapper">
        {listWords.map((word, index) => {
          return (
            <div
              key={'key-' + word.id}
              onClick={handleWordClick(word.id)}
              className="word"
            >
              {word.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Displays all autocomplete items..
 *
 * @param suggestion - current item to show
 * @param index - current item index
 * @param itemProps
 * @param highlightedIndex
 * @returns {XML}
 */
const renderSuggestionHandler = ({
  suggestion,
  index,
  highlightedIndex,
  itemProps,
  handleClick,
  clearSelection,
}) => {
  const isHighlighted = highlightedIndex === index;

  const onClick = () => {
    handleClick(suggestion);
    clearSelection();
  };

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      component="div"
      selected={isHighlighted}
      onClick={onClick}
    >
      {suggestion.label}
    </MenuItem>
  );
};

export default Vocabulary;

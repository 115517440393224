import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
// global
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ThemeProviders from './theme';

// custom
import FrontPage from './containers/FrontPage';
import LoginPage from './containers/LoginPage/LoginPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Aux from './hoc/Auxiliary/Auxiliary';
import VocabularyModal from './components/VocabularyModal/VocabularyModal';
import ReportRouter from './containers/ReportRouter';
import RedirectWas from './containers/RedirectWas';
import Page404 from './components/Page404';
import Maintenance from './components/Mainenance';

// css
import './App.scss';

function App() {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  const user = useSelector(({ user }) => user);

  const toggleMobileMenuHandler = (opened) => () => {
    setMobileMenuOpened(opened);
  };

  return (
    <BrowserRouter>
      <ThemeProviders>
        {!user.authenticated ? (
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <Redirect from="/" to="/login" />
            <Route path="/was" exact component={RedirectWas}/>
          </Switch>
        ) : (
          <Aux>
            <Header
              mobileMenuOpened={mobileMenuOpened}
              toggleMobileMenu={toggleMobileMenuHandler}
            />
            <Switch>
              <Route path="/was" exact component={RedirectWas}/>
              <Route path="/login" exact component={LoginPage} />
              <Route
                path="/report/:municipalityId/:cadastralId"
                component={Maintenance}
              />
              <Route
                path="/bfe/:bfeId"
                component={ReportRouter}
              />
              <Route path="/" exact component={FrontPage} />
              <Route component={Page404} />
            </Switch>

            <VocabularyModal />
            <Footer />
          </Aux>
        )}
      </ThemeProviders>
    </BrowserRouter>
  );
}

export default App;
